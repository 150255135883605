import { ChangeDetectionStrategy, Component, ViewEncapsulation } from '@angular/core';
import { ClientDialogMatDialogService } from 'app/features/client-dialog/client-dialog-mat-dialog.serivce';

@Component({
  selector: 'dq-client-dialog-within-notice-of-entry-confirm-dialog',
  templateUrl: './client-dialog-within-notice-of-entry-confirm-dialog.component.html',
  styleUrl: './client-dialog-within-notice-of-entry-confirm-dialog.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogWithinNoticeOfEntryConfirmDialogComponent {
  constructor(private clientDialogMatDialogService: ClientDialogMatDialogService) {

  }

  confirm() {
    this.clientDialogMatDialogService.closeWithInNoticeOfEntryDialog(true);
  }
}
