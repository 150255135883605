<div class="call-settings-component" cdkOverlayOrigin 
     [ngStyle]="{'background-image': !isDeviceRegistered ? 'linear-gradient(to top, var(--sys-primary) 0%, var(--sys-surface) 50%)' : 'unset'}">
  <div>
    <ng-template cdkConnectedOverlay [cdkConnectedOverlayOpen]="!!isCallPanelActive">
      <dq-on-going-call-handler class="call-handler-origin-place-holder"
                                (closePanel)="closePanel()"></dq-on-going-call-handler>
    </ng-template>
  </div>

  <ng-container *ngIf="(isDeviceRegistered$ | async); else registerPhone">
    <div class="container" [ngClass]="isDeviceRegistered ? 'm-fadeIn' : 'm-fadeOut'">
      <ng-container *ngIf="!isIncomingCallOnGoing && !isOutgoingCallOnGoing ">
        <div class="log-in-phone">
          <div class="phone-action-container" [ngClass]="{'disabled-div': isActionDisabled()}">
            <ng-container *ngIf="(isDeviceRegistered$ | async) && (token$ | async)">
              <button mat-mini-fab class="action-btn power-off-btn" (click)="doLogoutAndUnregister()">
                <mat-icon>power_settings_new</mat-icon>
              </button>
            </ng-container>
            <div>
              <button mat-mini-fab class="action-btn"
                      [ngClass]="{'dq-toggle-inactive-btn': !isDeviceLogVisible }"
                      (click)="isDeviceLogVisible = !isDeviceLogVisible">
                <mat-icon>view_headline</mat-icon>
              </button>
              <button mat-mini-fab class="action-btn"
                      [ngClass]="{'dq-toggle-inactive-btn': !isDeviceSettingVisible }"
                      (click)="isDeviceSettingVisible = !isDeviceSettingVisible">
                <mat-icon>settings</mat-icon>
              </button>
            </div>
          </div>
        </div>
      </ng-container>

      <ng-container *ngIf="!isIncomingCallOnGoing && !isOutgoingCallOnGoing">
        <section>
          <div class="section-content"
               [ngClass]="{'disabled-div': !isDeviceRegistered || (isIncomingCallOnGoing$ | async) || !!isOutgoingCallOnGoing || !!isCallPanelActive}">
            <mat-form-field class="call-from-form-field" appearance="outline">
              <mat-label>Call From</mat-label>
              <mat-select [formControl]="callFromPhoneNumberFormControl" [disabled]="!isDeviceRegistered">
                <mat-option *ngFor="let phone of (callFromPhoneNumbers$ | async)" [value]="phone.phoneNumber">
                  {{ phone.friendlyName }} {{ phone.phoneNumber }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </section>
      </ng-container>

      <div
        [ngClass]="{'disabled-div': !isDeviceRegistered || (isIncomingCallOnGoing$ | async) || !!isOutgoingCallOnGoing || !!isCallPanelActive}">
        <dq-dialog-pad (callByNumber)="startOutgoingCall($event)"></dq-dialog-pad>
      </div>

      <ng-container *ngIf="!isIncomingCallOnGoing && !isOutgoingCallOnGoing ">
        <section [ngClass]="isDeviceSettingVisible === true ? 'm-fadeIn' : 'm-fadeOut'">
          <ng-container *ngIf="isDeviceSettingVisible">
            <div class="section-content"
                 [ngClass]="{'disabled-div': !isDeviceRegistered || (isIncomingCallOnGoing$ | async) || !!isOutgoingCallOnGoing || !!isCallPanelActive}">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Speaker Devices</mat-label>
                <mat-select [formControl]="speakerDeviceFormControl" [disabled]="!isDeviceRegistered">
                  <mat-option *ngFor="let device of speakerMediaDevices" [value]="device.deviceId">{{ device.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
            <div class="section-content">
              <mat-form-field class="form-field" appearance="outline">
                <mat-label>Ringtone Devices</mat-label>
                <mat-select [formControl]="ringtoneDeviceFormControl" [disabled]="!isDeviceRegistered">
                  <mat-option *ngFor="let device of ringtoneMediaDevices" [value]="device.deviceId">{{ device.label }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </ng-container>
        </section>
      </ng-container>
      <!--using m-fadeIn and m-fadeOut that way can lead to display issues since it only sets the opacity.-->
      <section [ngClass]="isDeviceLogVisible === true ? 'm-fadeIn' : 'm-fadeOut'">
        <div id="log" class="section-content">
          <p *ngFor="let log of logs$ | async" class="log-entry">&gt;&nbsp;{{ log }} </p>
        </div>
      </section>
    </div>
  </ng-container>
  <ng-template #registerPhone>
    <div class="container">
      <div class="register-device-container">
        <div class="log-in-phone">
          <button mat-mini-fab class="register-button action-btn" (click)="doLoginAndRegister()">
            <mat-icon>power_settings_new</mat-icon>
          </button>
          <div class="action-btn-label">Turn on your phone</div>
        </div>
      </div>
    </div>
  </ng-template>
</div>
