import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import { AppState } from 'app/core/core.state';
import { TimeZone } from 'app/shared/models/timezone.model';
import { WeeklySchedule } from 'app/shared/models/weekschedule.model';
import { bookingRepresentativeCalenadarEventsReducer } from './components/booking-representative-calendar/booking-representative-calendar.reducer';
import { bookingAccountReducer } from './components/booking-account/booking-account.reducer';
import { bookingBuildingAvailabilityReducer } from './components/booking-building-avaliabilty/booking-building-avaliabilty.reducer';
import { bookingRepresentativeEventCalendarReducer } from './components/booking-calendar/booking-calendar.reducer';
import { bookingAccountRepresentativesReducer } from './components/booking-account-representatives/booking-account-representatives.reducer';
import { LeadPreference } from 'app/shared/models/lead-preference.model';


export const FEATURE_NAME = 'booking';

export const selectBookingState = createFeatureSelector<BookingState>(
  FEATURE_NAME
);

export const reducers: ActionReducerMap<BookingState> = {
  accounts: bookingAccountReducer,
  accountRepresentatives: bookingAccountRepresentativesReducer,
  representativeCalendarEvents: bookingRepresentativeCalenadarEventsReducer,
  eventCalendar: bookingRepresentativeEventCalendarReducer,
  buildingAvailability: bookingBuildingAvailabilityReducer
};

export interface BookingState {
  accounts: BookingAccountState,
  accountRepresentatives: BookingAccountRepresentativesState,
  representativeCalendarEvents: BookingRepresentativeCalendarEventsState,
  eventCalendar: BookingEventCalendarState,
  buildingAvailability: BookingBuildingAvailabilityState
}

export interface BookingAccountState {
  isLoaded: boolean,
  accounts: Account[]
}

export interface Account {
  id: string;
  name: string;
  type: string;
  uri: string
}


export interface BookingAccountRepresentativesState {
  isLoaded: boolean,
  representativeThumbnails: RepresentativeThumbnail[],
  account: Account,
  buildings: IBuilding[]
  buildingRepresentatives: RepresentativeThumbnail[],
  isBuildingRepresentativesLoaded: boolean,
  buildingUnits: IUnit[],
  isBuildingUnitsLoaded: boolean,
  isBuildingsLoaded: boolean,
}


export interface BookingBuildingAvailabilityState {
  dateFilter: string
  buildingSelected: IBuilding
  buildingSelectedId: number
  buildings: IBuilding[]
  buildingWeeklyAvailability: BuildingWeeklyAvailability,
  isBuildingAvailabilityLoaded: boolean
  isBuildingsLoaded: boolean
  availabilitySelected: string
  isBookingCalendarsLoaded: boolean
  bookingCalendars: BookingCalendar[],
  isScheduleOverwrite: boolean
}

export interface BuildingWeeklyAvailability {
  weekStartDate: string;
  weekEndDate: string;
  weeklyScheduleDays: WeeklyAvailabilityDays[];
}

export interface WeeklyAvailabilityDays {
  date: string;
  weeklyScheduleItems: WeeklyAvailabilityItem[]
}

export interface WeeklyAvailabilityItem {
  bookingSlotDateTime: string
  calendarIds: number[]
}

export interface IUnit {
  id: number;
  buildingId: number;
  buildingName: string;
  buildingAddress: string;
  vacancyDate?: string;
  isFurnished?: boolean;
  unitNumber: string;
  beds?: number;
  baths?: number;
  price?: number;
  floorPlanId?: string;
  specialPrice?: number
  buildingPhotoUrl: string;
  isReserved?: boolean;
  reservedBy?: string
  canRelease?: boolean
}

export interface BookingRepresentativeCalendarEventsState {
  isLoaded: boolean,
  repSelectedUserId: number,
  representative: RepresentativeThumbnail,
  ifMicrosoftConnected: boolean
  ifZoomConnected: boolean
  events: BookingEvent[]
  isAppointmentsLoaded: boolean
  appointments: IAppointment[]
  isWeekScheduleLoading: boolean
  weekSchedule: WeeklySchedule
}

export interface BookingEventCalendarState {
  isLoaded: boolean,
  buildingAddress: string,
  calendarIdSelected: number,
  representative: RepresentativeThumbnail,
  buildingId: number,
  buildingProperties: BuildingProperty[]
  event: BookingEvent,
  date: string,
  timeZoneOptions: TimeZone[],
  timeZone: TimeZone,
  timeSlot: BookingTimeSlot,
  timeToConfirmId: string,
  timeSlots: BookingTimeSlot[],
  timeSlotsLoaded: boolean,
  isSubmitting: boolean,
  minCalendarDate: Date,
  leadSources: LeadSource[],
  isFormActive: boolean,
  leadPreferences: LeadPreference[],
  isScheduleOverwrite: boolean
}

export interface BuildingProperty {
  id: number
  name: string
  streetAddress: string
  country: string
  state: string
  city: string
  postalCode: string
}

export interface Developer {
  id: number;
  name: string;
  logoUri: string
}

export interface BookingTimeSlot {
  id?: string;
  date: string;
  isWithinNoticeOfEntry?: boolean;
}

export interface LeadSource {
  id: number;
  shortName: string;
  isDefault: boolean;
}

export interface IAppointment {
  id: number;
  name: string;
  startTime: string;
  endTime: string;
  clientName: string;
  clientEmail: string;
  clientPhoneNumber: string;
  representativeName: string;
  leadId: number;
  representativeUri?: string;
  buildingId: number;
  buildingName: string;
  sourceId: number;
  sourceName: string;
  timeZone: string;
}

export interface RepresentativeThumbnail {
  id: number;
  firstName: string;
  lastName: string;
  uri: string;
  photoUri: string;
}

export interface BookingEvent {
  id: number
  name: string
  uri: string
  description: string
  duration?: number
  timeZone?: string,
  representativeId: string
  representativeCalendarTypeId?: string
  representativeCalendarType: BookingRepresentativeCalendarType
  externalCalendarRef: string
  colorCode: string
  bookingStartTimeLocal: string
  bookingEndTimeLocal: string
}

export interface BookingCalendar {
  id: number
  name: string
  uri: string
  representativeUri: string
  representativeName: string
  colorCode: string
}
export interface BookingRepresentativeCalendarType {
  name: string;
  type: string;
}

export interface IBuilding {
  id: number;
  photoUrl: string;
  name: string;
  style: string;
  address: string;
  activationDate: string
}

export interface State extends AppState {
  [FEATURE_NAME]: BookingState;
}
