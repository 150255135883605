<div class="client-dialog-inventory-booking-component">
    <div class="desktop-container">
        <div mat-dialog-title class="mat-dialog-title">
            <div class="box left">
                <ng-container *ngIf="(bookingStep$ | async) > 1">
                    <div class="back-div" (click)="back()">
                        <mat-icon svgIcon="arrow-left"></mat-icon>
                        <span>Back</span>
                    </div>
                </ng-container>
            </div>
            <div class="box center">
                <ng-container *ngIf="appointmentEdittingId < 0">
                    <span class="book-appointment-title">Book an Appointment</span>
                </ng-container>
                <ng-container *ngIf="appointmentEdittingId > 0">
                    <ng-container *ngIf="(appointmentEditting$ | async) as appointment">
                        <span class="book-appointment-title update-appointment-title">
                            Updating <span style="text-decoration: underline;">{{appointment.clientName}}'s</span>
                            appointment with
                            <span style="text-decoration: underline;">{{appointment.representativeName}}</span>
                            on {{appointment.startTime | date: 'MMM d, y, h:mm a'}} - {{appointment.timeZone}}
                        </span>
                    </ng-container>
                </ng-container>
            </div>
            <div class="box right">
                <mat-icon svgIcon="times" class="icon cancel-button"
                    (click)="$event.preventDefault();close()"></mat-icon>
            </div>
        </div>
        <mat-divider></mat-divider>
        <ng-container *ngIf="(bookingStep$ | async) === 1">
            <div class="week-schedule-container">
                <div class="main-container">
                    <div class="filter-header">
                        <div class="date-filter-div dq-flex-aligned">
                            <mat-form-field class="no-subscript-wrapper building-selector" appearance="outline"
                                disabled>
                                <mat-select placeholder="Buildings" #singleSelect
                                    [formControl]="buildingSelectedControl">
                                    <mat-option>
                                        <ngx-mat-select-search placeholderLabel="Find building..."
                                            noEntriesFoundLabel="'no matching building'"
                                            [formControl]="searchBuildingControl">
                                        </ngx-mat-select-search>
                                    </mat-option>
                                    <mat-option *ngFor="let building of (buildings$ | async)" [value]="building.id"
                                        (click)="buildingSelectedChange(building)">
                                        <div class="building-select-info-div">
                                            <img class="book-building-selected-img" src="{{building.photoUrl}}"
                                                alt="{{building.name}}" title="{{building.name}}" />
                                            <span>
                                                {{building.name}}
                                            </span>
                                        </div>
                                    </mat-option>
                                </mat-select>
                            </mat-form-field>
                            <ng-container *ngIf="propertyName && unitNumber">
                                <span class="unit-info">{{propertyName}} - {{unitNumber}}</span>
                            </ng-container>

                            <ng-container *ngIf="(buildingSelected$ | async).id > 0">
                                <button mat-icon-button class="left-right-icon" aria-label="Previous week"
                                    (click)="dateBuildingChangeToPreviousWeek()">
                                    <mat-icon>keyboard_arrow_left</mat-icon>
                                </button>
                                <button mat-icon-button class="left-right-icon" aria-label="Next week"
                                    (click)="dateBuildingChangeToNextWeek()">
                                    <mat-icon>keyboard_arrow_right</mat-icon>
                                </button>
                                <button mat-stroked-button class="date-picker-button" mat-button
                                    (click)="buildingScheduileDatepicker.open()">
                                    <input class="date-input" [formControl]="buildingDateControl"
                                        [matDatepicker]="buildingScheduileDatepicker">
                                    <mat-datepicker #buildingScheduileDatepicker></mat-datepicker>
                                    <mat-icon class="filter-icon" svgIcon="calendar-check">
                                    </mat-icon>
                                    <ng-container
                                        *ngIf="(buildingWeeklyAvailability?.weekStartDate && buildingWeeklyAvailability?.weekEndDate);else fakeDate">
                                        <span>{{buildingWeeklyAvailability.weekStartDate | date: 'MMM d y': 'UTC'}}
                                            -
                                            {{buildingWeeklyAvailability.weekEndDate | date: 'MMM d
                                            y'
                                            :'UTC'}}</span>
                                    </ng-container>
                                    <ng-template #fakeDate>
                                        {{today | date: 'MMM d y': 'UTC'}}
                                    </ng-template>

                                </button>
                                <mat-checkbox class="overwrite-schedule-chk" [ngModel]="isScheduleOverwrite$ | async"
                                    (change)="overWriteScheduleChange($event)"> Overwrite Schedule</mat-checkbox>
                            </ng-container>
                        </div>
                        <button mat-stroked-button class="today-button" mat-stroked-button
                            (click)="dateBuildingChangeToToday()">Today</button>
                    </div>
                    <div class="schedule-container">
                        <ng-container *ngIf="(isBuildingsLoaded$ | async); else buildingsLoading">
                            <ng-container *ngIf="(buildingSelected$ | async).id < 0; else buildingSelectedScheduler">
                                <div class="select-agent-hint">
                                    Please select a building
                                </div>
                            </ng-container>
                        </ng-container>
                        <ng-template #buildingsLoading>
                            <mat-spinner class="buildings-loading-spinner"></mat-spinner>
                        </ng-template>
                        <ng-template #buildingSelectedScheduler>
                            <ng-container
                                *ngIf="!(isBuildingWeeklyAvailabilityloaded$ | async); else buildingScheduleLoaded">
                                <mat-spinner class="loading-spinner"></mat-spinner>
                            </ng-container>
                            <ng-template #buildingScheduleLoaded>
                                <ng-container
                                    *ngFor="let weeklyAvailabilityDay of buildingWeeklyAvailability.weeklyScheduleDays; let i=index">
                                    <div class="weekday-div">
                                        <div class="weekday-header">
                                            {{weeklyAvailabilityDay.date | date: 'EEEE, MMM d, y': 'UTC'}}
                                        </div>
                                        <div class="weekday-content">
                                            <div class="time-slot-list">
                                                <ng-container
                                                    *ngFor="let availability of weeklyAvailabilityDay.weeklyScheduleItems">
                                                    <ng-container
                                                        *ngIf="(availabilitySelected$ | async) !== availability.bookingSlotDateTime; else toConfirm">
                                                        <div class="time-slot-item" [ngClass]="{'time-slot-within-notice': availability.isWithinNoticeOfEntry}"
                                                            (click)="changeTimeIdToConfirm(availability.bookingSlotDateTime)">
                                                            <span>{{availability.bookingSlotDateTime | date: 'h:mm
                                                                a'}}</span>
                                                        </div>
                                                    </ng-container>
                                                    <ng-template #toConfirm>
                                                        <div class="time-confirm-div">
                                                            <button mat-button class="time-button"
                                                                (click)="changeTimeIdToConfirm('')">{{availability.bookingSlotDateTime
                                                                |
                                                                date: 'h:mm a'}}</button>
                                                            <button mat-flat-button class="confirm-button"
                                                                (click)="confirmTime(availability)">Confirm</button>
                                                        </div>
                                                    </ng-template>
                                                </ng-container>
                                                <ng-container
                                                    *ngIf="weeklyAvailabilityDay.weeklyScheduleItems.length === 0">
                                                    <div class="no-time-slots-div">
                                                        Not Available
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>
                            </ng-template>
                        </ng-template>
                    </div>
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="(bookingStep$ | async) === 2">
            <div class="calendars-container">
                <ng-container *ngIf="(isBookingCalendarsLoaded$ | async) else loadings">
                    <ng-container *ngFor="let calendar of (calendars$ | async)">
                        <div class="calendar">
                            <div class="calendar-info" [style.border-color]="calendar.colorCode"
                                (click)="confirm(calendar)">
                                <div class="calendar-rep-name">
                                    {{calendar.representativeName}}
                                </div>
                                <div class="calendar-name">
                                    {{calendar.name}}
                                </div>
                            </div>
                        </div>
                    </ng-container>


                </ng-container>
                <ng-template #loadings>
                    <mat-spinner class="loading-spinner"></mat-spinner>
                </ng-template>
            </div>
        </ng-container>
        <ng-container *ngIf="(bookingStep$ | async) === 3">
            <dq-client-dialog-booking-calendar [propertyId]="propertyId" [propertyName]="propertyName"
                [propertyAddress]="propertyAddress" [unitNumber]="unitNumber" [unitId]="unitId"></dq-client-dialog-booking-calendar>
        </ng-container>

    </div>
</div>