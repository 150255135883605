import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Inject, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subject } from 'rxjs';

import { State } from 'app/features/client-dialog/client-dialog.state';
import { ReplaySubject } from 'rxjs';
import { selectClientDialogIsClientRentalApplicationAddNewFormSending } from '../../client-dialog-rental-application.selector';
import { actionClientDialogApplicationAddNewManualRentalApplicationDocumentRequested, actionClientDialogApplicationAddNewManualRentalRequestDocumentRequested } from '../../client-dialog-rental-application.action';
import { takeUntil } from 'rxjs/operators';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';

@Component({
  selector: 'dq-client-dialog-rental-application-form-add-new-manual',
  templateUrl: './client-dialog-rental-application-form-add-new-manual.component.html',
  styleUrls: ['./client-dialog-rental-application-form-add-new-manual.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class ClientDialogRentalApplicationFormAddNewManualComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    @Inject('dialogData') private dialogData: any,
    private cdr: ChangeDetectorRef) {
    this.rentalApplicationId = dialogData.rentalApplicationId;
    this.rentalApplicantFirstName = dialogData.rentalApplicantFirstName;
    this.rentalApplicantLastName = dialogData.rentalApplicantLastName;
    this.rentalRequestId = dialogData.rentalRequestId;
  }

  unsubscribe$: Subject<void> = new Subject<void>();

  rentalRequestId: number;

  rentalApplicationId: number;
  rentalApplicantFirstName: string;
  rentalApplicantLastName: string;

  isRentalApplicationAddNewFormSending$: Observable<boolean>;

  fileName: string;
  fileSize: number;
  fileType: string;
  fileBase64: string;
  fileValid: boolean = false;

  spinnerAddNewFormButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Upload',
    flat: true, spinnerSize: 18,

    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  ngOnInit(): void {
    this.store.select(selectClientDialogIsClientRentalApplicationAddNewFormSending).pipe(takeUntil(this.unsubscribe$)).subscribe(isSending => {
      this.spinnerAddNewFormButtonOptions.active = isSending;
    });
  }

  onFileSelected(event) {
    const file: File = event.target.files[0];
    this.fileValid = true;

    if (file) {
      this.convertFile(event.target.files[0]).subscribe(base64 => {
        this.fileBase64 = base64;
        this.fileValid = true;
        this.cdr.markForCheck();
      });

      this.fileName = file.name;
      this.fileSize = file.size;
      this.fileType = file.type;
    }
  }

  convertFile(file: File): Observable<string> {
    const result = new ReplaySubject<string>(1);
    const reader = new FileReader();
    reader.readAsBinaryString(file);
    reader.onload = (event) => result.next(btoa(reader.result.toString()));
    return result;
  }

  submitForm() {
    if (this.rentalApplicationId != null && this.fileBase64 !== null) {
      this.store.dispatch(actionClientDialogApplicationAddNewManualRentalApplicationDocumentRequested({ rentalApplicationId: this.rentalApplicationId, fileName: this.fileName, file: this.fileBase64 }));
    }
    else if (this.rentalRequestId != null && this.fileBase64 !== null) {
      this.store.dispatch(actionClientDialogApplicationAddNewManualRentalRequestDocumentRequested({ rentalRequestId: this.rentalRequestId, fileName: this.fileName, file: this.fileBase64 }));
    }
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
