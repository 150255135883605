<div class="unit-table-header">
  <div class="dq-row">
    <div class="col-sm-4">
      <span>Building</span>
    </div>
    <div class="col-sm-1">
      <span>#Unit</span>
    </div>
    <div class="col-sm-2">
      <span>Price</span>
    </div>
    <div class="col-sm-2">
      <span>Beds/Baths</span>
    </div>
    <div class="col-sm-1">
      <span>Sqft</span>
    </div>
    <div class="col-sm-2">
      <span>Made Ready Date</span>
    </div>
  </div>
</div>
<div class="unit-list-container">
  <ng-container *ngFor="let unit of (units$ | async); let i = index">
    <div class="dq-row unit-detail"
      [ngClass]="{'selected-unit': selectedUnit === unit, 'hover-unit': hoveredUnit === unit}"
      (click)="selectUnit(unit)" (mouseenter)="hoveredUnit = unit" (mouseleave)="hoveredUnit = null">
      <div class="col-sm-4">
        <div class="unit-detail-info">{{unit.buildingName}}
        </div>
      </div>
      <div class="col-sm-1">
        <div class="unit-detail-info">{{unit.yardiUnitIdentifier}}</div>
      </div>
      <div class="col-sm-2">
        <div class="unit-detail-info">
          <ng-container *ngIf="unit.price > 0; else priceNA">
            ${{unit.price | number: '1.2-2'}}
          </ng-container>
          <ng-template #priceNA>Not Available</ng-template>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="unit-detail-info">{{unit.beds}} BD | {{unit.baths}} BA</div>
      </div>
      <div class="col-sm-1">
        <div class="unit-detail-info">
          <ng-container *ngIf="unit.unitSquareFeet > 0; else sqftNA">
            {{unit.unitSquareFeet | number: '1.0-0'}}
          </ng-container>
          <ng-template #sqftNA>Not Available</ng-template>
        </div>
      </div>
      <div class="col-sm-2">
        <div class="unit-detail-info">{{unit.madeReadyDate | date: 'MMM d, y'}}</div>
      </div>
    </div>
  </ng-container>
</div>
<div class="action-buttons dq-flex-centered">
  <dq-spinner-button type="button" (click)="updateUnit()" [options]="spinnerCreateButtonOptions"></dq-spinner-button>
</div>