<div class="on-going-call-handler-component" cdkDragBoundary=".cdk-overlay-container" cdkDrag>
  <ng-container *ngIf="isIncomingCallPanelActive$ | async">
    <ng-container *ngIf="isIncomingCallConnected$ | async; else calling">
      <div class="phone-action-container">
        <button mat-mini-fab (click)="isTransferActive = !isTransferActive" class="action-btn"
          [ngClass]="{'dq-toggle-inactive-btn': !isTransferActive }">
          <mat-icon>phone_forwarded</mat-icon>
        </button>
        <button mat-mini-fab (click)="toggleInComingCallMute()" class="action-btn"
          [ngClass]="{'dq-toggle-inactive-btn': !isMuteActive }">
          <mat-icon>{{getMicOnOff()}}</mat-icon>
        </button>
        <button mat-mini-fab (click)="isDialActive = !isDialActive" class="action-btn"
          [ngClass]="{'dq-toggle-inactive-btn': !isDialActive }">
          <mat-icon>dialpad</mat-icon>
        </button>
          <button #block mat-mini-fab matTooltip="Hold button for 2 secs to block phone number!" (click)="blockPhoneNumber()" class="action-btn power-off-btn">
          <mat-icon>cancel</mat-icon>
        </button>
        <mat-icon class="drag-icon" cdkDragHandle>drag_indicator</mat-icon>
      </div>
      <div class="call-info-div">
        <div class="call-info-direction-row"><mat-icon>call_received</mat-icon> Incoming call <button
            class="recording-button"></button></div>
        <div class="call-info-row"><label>From:</label><span>{{getCallParameterFrom()}}</span></div>
        <div class="call-info-row"><label>To:</label><span>{{getCallParameterTo()}}</span></div>
        <div class="call-duration">
          {{callDurationDisplay}}
        </div>
      </div>
      <ng-container *ngIf="isDialActive">
        <dq-on-going-dialog-pad (pressKey)="pressIncomingCallKey($event)"
          isDialActive="isDialActive"></dq-on-going-dialog-pad>
      </ng-container>
      <div class="phone-action-container">
        <button mat-mini-fab (click)="hangupIncomingCall()" class="call-end-btn power-off-btn" aria-label="Hang Up">
          <mat-icon>call_end</mat-icon>
        </button>
      </div>
    </ng-container>
    <ng-template #calling>
      <div class="call-info-div">
        <div class="call-info-direction-row"><mat-icon>call_received</mat-icon> Incoming call</div>
        <div class="call-info-row"><label>From:</label><span>{{getCallParameterFrom()}}</span></div>
        <div class="call-info-row"><label>To:</label><span>{{getCallParameterTo()}}</span></div>
      </div>
      <div class="phone-action-container">
        <button mat-mini-fab class="action-btn" aria-label="Accept" (click)="acceptIncomingCall()">
          <mat-icon>call</mat-icon>
        </button>
        <button mat-mini-fab class="action-btn power-off-btn" aria-label="Reject" (click)="rejectIncomingCall()">
            <mat-icon>call_end</mat-icon>
          </button>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="isOutgoingCallPanelActive$ | async">
    <ng-container *ngIf="(isOutgoingCallConnected$ | async) == true; else connecting">
      <div class="phone-action-container">
        <button mat-mini-fab (click)="isTransferActive = !isTransferActive" class="action-btn"
          [ngClass]="{'dq-toggle-inactive-btn': !isTransferActive }">
          <mat-icon>phone_forwarded</mat-icon>
        </button>
        <button mat-mini-fab (click)="toggleOutgoingCallMute()" class="action-btn"
          [ngClass]="{'dq-toggle-inactive-btn': !isMuteActive }">
          <mat-icon>{{getMicOnOff()}}</mat-icon>
        </button>
        <button mat-mini-fab (click)="isDialActive = !isDialActive" class="action-btn"
          [ngClass]="{'dq-toggle-inactive-btn': !isDialActive }">
          <mat-icon> dialpad</mat-icon>
        </button>
        <mat-icon class="drag-icon" cdkDragHandle>drag_indicator</mat-icon>
      </div>
      <div class="call-info-div">
        <div class="call-info-direction-row"><mat-icon>call_made</mat-icon> Outgoing call <button
            class="recording-button"></button></div>
        <div class="call-info-row"><label>From:</label><span>{{currentOutgoingFromPhoneNumber$ | async}}</span></div>
        <div class="call-info-row"><label>To:</label><span>{{currentOutgoingToPhoneNumber$ | async}}</span></div>
        <div class="call-duration">
          {{callDurationDisplay}}
        </div>
      </div>
      <ng-container *ngIf="isDialActive">
        <dq-on-going-dialog-pad (pressKey)="pressOutgoingCallKey($event)"
          isDialActive="isDialActive"></dq-on-going-dialog-pad>
      </ng-container>
      <div class="phone-action-container">
        <button mat-mini-fab class="action-btn power-off-btn" aria-label="Reject" (click)="hangupOutgoingCall()">
          <mat-icon>call_end</mat-icon>
        </button>
      </div>
    </ng-container>
    <ng-template #connecting>
      <div class="connecting-container">
        <div>
          <div class="dot-stretching"></div>
          <div class="connecting-label">Connecting to the call</div>
        </div>
      </div>
    </ng-template>
  </ng-container>

  <ng-container *ngIf="isTransferActive">
    <form (submit)="transfertCall()">
      <div class="section-content">
        <mat-form-field class="search-full-width no-subscript-wrapper" appearance="outline">
          <mat-label>Transfer To</mat-label>
          <input type="text" placeholder="Pick person" matInput [formControl]="transferPhoneQueueFormControl"
             [matAutocomplete]="auto">
            <mat-autocomplete autoActiveFirstOption #auto="matAutocomplete" [displayWith]="displayTransferPhoneQueue">
              <mat-option *ngFor="let queue of (transferPhoneQueueSearchOptions$ | async)"
              [value]="queue">
              {{queue.name}}
            </mat-option>
          </mat-autocomplete>
          <button type="button" [disabled]="!transferPhoneQueueFormControl.value" mat-icon-button (click)="transferPhoneQueueClean()" matSuffix><mat-icon>close</mat-icon></button>
        </mat-form-field>
        <button *ngIf="!!transferPhoneQueueFormControl.value?.id" type="submit" mat-stroked-button class="transfer-button">Transfer Call</button>
      </div>
    </form>
  </ng-container>
</div>