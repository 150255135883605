<div class="client-dialog-rental-application-component">
    <div class="main-container">
        <ng-container *ngIf="(isRentalRequestLoaded$ | async); else loading">
            <ng-container *ngIf="rentalRequest$ | async as rentalRequest; else noRentalRequest">
                <div class="rental-request-section dq-box-shadow">
                    <div class="section-header">
                        <div class="activity-toggle-div dq-flex">
                            <mat-slide-toggle (change)="toggleShowRentalRequestActivities($event)"
                                [checked]="(ifShowRentalRequestActivities$ | async)"></mat-slide-toggle>
                            <span class="activity-text">Activities</span>
                        </div>
                        <button class="rental-request-btn" mat-stroked-button [matMenuTriggerFor]="rentalRequestMenu">
                            <span>#{{rentalRequest.inventoryFriendlyName}}</span>
                            <mat-icon>keyboard_arrow_down</mat-icon>
                        </button>
                        <mat-menu #rentalRequestMenu="matMenu">
                            <ng-container *ngFor="let rentalRequestListItem of (rentalRequestListItems$ | async)">
                                <button mat-menu-item (click)="onRentalRequestChange(rentalRequestListItem.id)">
                                    <ng-container *ngIf="rentalRequestListItem.id == rentalRequest.id">
                                        <mat-icon>done</mat-icon>
                                    </ng-container>
                                    <span>{{rentalRequestListItem.inventoryFriendlyName}} | {{
                                        getRentalRequestStatusLabel(rentalRequestListItem.status) }}</span>
                                </button>
                            </ng-container>
                        </mat-menu>
                        <div class="menu-action dq-flex-centered">
                            <button mat-icon-button [matMenuTriggerFor]="menu">

                                <mat-icon>more_vert</mat-icon>
                            </button>
                            <mat-menu #menu="matMenu">
                                <button mat-menu-item [disabled]="isAddApplicantDisabled()" (click)="openAddDialog()">
                                    <mat-icon>add</mat-icon>
                                    <span>Add an Applicant</span>
                                </button>
                                <button mat-menu-item [disabled]="isChangeUnitDisabled()"
                                    (click)="openChangeUnitDialog()">
                                    <mat-icon>domain</mat-icon>
                                    <span>Change Unit</span>
                                </button>
                                <div *ngIf="rentalRequest.status == rentalRequestStatuses.CREATED">
                                    <button mat-menu-item [disabled]="isStartRentalRequestDisabled()"
                                        (click)="onStartRentalRequest(rentalRequest.id)">
                                        <mat-icon>input</mat-icon>
                                        <span>Send Rental Application</span>
                                    </button>
                                </div>
                                <ng-container *ngIf="isRollbackEnabled()">
                                    <button mat-menu-item (click)="onRollbackToInProgress(rentalRequest.id)">
                                        <mat-icon>settings_backup_restore</mat-icon>
                                        <span>Rollback to In Progress</span>
                                    </button>
                                </ng-container>
                                <button mat-menu-item [disabled]="isReadyForReviewDisabled()"
                                    (click)="onReadyForReview(rentalRequest.id)">
                                    <mat-icon>visibility</mat-icon>
                                    <span>Ready for Review</span>
                                </button>
                                <button mat-menu-item
                                    [disabled]="!(rentalRequest.status == rentalRequestStatuses.IN_REVIEW && (isApplicationApprovalsManager() | async))"
                                    (click)="onApproveRentalRequest(rentalRequest.id)">
                                    <mat-icon>done</mat-icon>
                                    <span>Approve</span>
                                </button>
                                <button mat-menu-item
                                    [disabled]="!(rentalRequest.status == rentalRequestStatuses.APPROVED && (isLeaseSignatory() | async))"
                                    (click)="onSignRentalRequest(rentalRequest.id)">
                                    <mat-icon class="action-icon" svgIcon="signature"></mat-icon>
                                    <span>Sign</span>
                                </button>
                                <button mat-menu-item
                                    [disabled]="isRentalRequestEditActionDisabled() || !(isApplicationCancellationManager() | async)"
                                    (click)="openCancelRequestDialog(rentalRequest.id)">
                                    <mat-icon>cancel</mat-icon>
                                    <span>Cancel / Decline</span>
                                </button>
                            </mat-menu>
                        </div>
                    </div>
                    <mat-divider></mat-divider>
                    <div class="rental-request-detail-container">
                        <div class="rental-request-info">
                            <div class="rental-request-field-label">
                                <span
                                    class="dq-font-weight-700 rental-request-big-info">{{getRentalRequestStatusLabel(rentalRequest.status)}}</span>
                                <ng-container *ngIf="rentalRequest.status == rentalRequestStatuses.CANCELLED">
                                    <span class="dq-font-weight-500 rental-request-big-info">
                                        ({{getApplicationCancelReasonLabel(rentalRequest.cancelReasonType)}})</span>
                                </ng-container>
                            </div>
                            <div class="rental-request-field-label"><span class="rental-request-big-info">Unit Rent
                                    Price - <span
                                        class="dq-font-weight-700">${{rentalRequest.rentalPrice}}</span></span></div>
                            <div class="rental-request-field-label">Located at <span
                                    class="dq-font-weight-700">{{rentalRequest.buildingPropertyAddress}}</span></div>
                            <div class="rental-request-field-label">Created on <span
                                    class="dq-font-weight-700">{{rentalRequest.creationDate | date: 'EEEE, MMM
                                    d,y'}}</span></div>
                            <ng-container *ngIf="rentalRequest.isParkingRequested">
                                <div class="rental-request-field-label"><span class="dq-font-weight-700">Applicant(s)
                                        would like to have a parking spot.</span></div>
                            </ng-container>
                            <ng-container *ngIf="rentalRequest.isStorageRequested">
                                <div class="rental-request-field-label"><span class="dq-font-weight-700">Applicant(s)
                                        would like to have a storage</span></div>
                            </ng-container>
                        </div>
                        <div class="row">
                            <form class="leasing-date-form" [formGroup]="rentalRequestLeasingDateForm">
                                <div class="dq-flex-centered leasing-date-fields">
                                    <div class="leasing-date-wrapper" (click)="moveInDatePicker.open()">
                                        <div class="leasing-date-picker">
                                            <mat-datepicker #moveInDatePicker></mat-datepicker>
                                            <mat-datepicker-toggle [for]="moveInDatePicker"></mat-datepicker-toggle>
                                        </div>
                                        <div class="leasing-date-input"><input style="visibility: hidden; width: 0;"
                                                [matDatepicker]="moveInDatePicker" formControlName="moveInDate"></div>
                                        <div class="dq-flex-centered">Move in Date</div>
                                        <div class="dq-flex-centered"><span
                                                class="dq-font-weight-700">{{rentalRequestLeasingDateForm.get('moveInDate').value
                                                | date: 'EEEE, MMM d, y'}}</span></div>
                                    </div>
                                    <div class="leasing-date-wrapper" (click)="leaseStartDatePicker.open()">
                                        <div class="leasing-date-picker">
                                            <mat-datepicker #leaseStartDatePicker></mat-datepicker>
                                            <mat-datepicker-toggle [for]="leaseStartDatePicker"></mat-datepicker-toggle>
                                        </div>
                                        <div class="leasing-date-input"><input style="visibility: hidden; width: 0;"
                                                [matDatepicker]="leaseStartDatePicker" formControlName="leaseStartDate">
                                        </div>
                                        <div class="dq-flex-centered">Lease Start Date</div>
                                        <div class="dq-flex-centered"><span
                                                class="dq-font-weight-700">{{rentalRequestLeasingDateForm.get('leaseStartDate').value
                                                | date: 'EEEE, MMM d, y'}}</span></div>
                                    </div>
                                    <div class="leasing-date-wrapper" (click)="leaseEndDatePicker.open()">
                                        <div class="leasing-date-picker">
                                            <mat-datepicker #leaseEndDatePicker></mat-datepicker>
                                            <mat-datepicker-toggle [for]="leaseEndDatePicker"></mat-datepicker-toggle>
                                        </div>
                                        <div class="leasing-date-input"><input style="visibility: hidden; width: 0;"
                                                [matDatepicker]="leaseEndDatePicker" formControlName="leaseEndDate">
                                        </div>
                                        <div class="dq-flex-centered">Lease End Date</div>
                                        <div class="dq-flex-centered"><span
                                                class="dq-font-weight-700">{{rentalRequestLeasingDateForm.get('leaseEndDate').value
                                                | date: 'EEEE, MMM d, y'}}</span></div>
                                    </div>
                                </div>
                                <ng-container *ngIf="leasingDateError != ''">
                                    <div class="dq-flex-centered">
                                        <div class="leasing-date-error-message">{{leasingDateError}}</div>
                                    </div>
                                </ng-container>
                                <ng-container *ngIf="!rentalRequestLeasingDateForm.pristine">
                                    <div class="dq-flex-centered">
                                        <dq-spinner-button type="button" (click)="onUpdateLeasingDates()"
                                            [options]="spinnerUpdateButtonOptions"></dq-spinner-button>
                                    </div>
                                </ng-container>
                            </form>
                        </div>
                        <ng-container *ngIf="ifShowRentalRequestActivities$ | async">
                            <div class="rental-request-activity-div">
                                <mat-divider></mat-divider>
                                <h3>Activities</h3>
                                <ng-container
                                    *ngIf="(isRentalRequestActivitiesLoaded$ | async ); else activitiesLoading">
                                    <div class="activities-list-div">
                                        <ng-container *ngFor="let activity of (rentalRequestActivities$ | async)">
                                            <div class="activity-item">
                                                <div class="activity-date">{{activity.creationDate +'Z' | date
                                                    :'medium'}}
                                                </div>
                                                <div class="activity-description">{{activity.operatorName}}
                                                    {{activity.description}}</div>
                                                <!-- <div class="activity-operator-name">{{activity.operatorName}}</div> -->
                                            </div>
                                        </ng-container>
                                    </div>
                                    <ng-container
                                        *ngIf="(rentalRequestActivities$ | async).length < rentalRequestActivitiesTotalRecords">
                                        <div class="row">
                                            <div class="col-12 button-div">
                                                <dq-spinner-button type="button"
                                                    (click)="loadMoreRentalRequestActivities()"
                                                    [options]="spinnerButtonOptions"></dq-spinner-button>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-template #activitiesLoading>
                                    <mat-spinner class="loading-spinner"></mat-spinner>
                                </ng-template>
                            </div>
                        </ng-container>
                    </div>
                </div>

                <ng-container *ngIf="(rentalApplication$ | async) != null">
                    <div class="deposit-section dq-box-shadow">
                        <div class="rental-request-deposit-payment-section contrast-offset-background-color">
                            <div class="section-title dq-flex">
                                <div>Deposit / Payment</div>
                            </div>
                        </div>

                        <ng-container
                            *ngIf="rentalRequest?.rentalRequestPaymentTransactions?.length > 0; else noDeposit">
                            <ng-container *ngFor="let transaction of rentalRequest?.rentalRequestPaymentTransactions">
                                <div class="rental-application-deposit">
                                    <div class="dq-flex-centered deposit-label">
                                        {{getTransactionMessage(transaction)}}
                                    </div>
                                </div>
                            </ng-container>
                        </ng-container>

                        <ng-template #noDeposit>
                            <div class="dq-flex-centered no-deposit-div">
                                No pre authorized deposit at the moment
                            </div>
                        </ng-template>
                    </div>

                    <div class="rental-request-document-section dq-box-shadow">
                        <div class="rental-request-document-container">
                            <div class="rental-request-document-title-section contrast-offset-background-color">
                                <div class="dq-flex">
                                    <div>Rental Items</div>
                                </div>
                                <div class="dq-flex-centered">
                                    <div class="add-action dq-flex-centered">
                                        <span class="add-label">Add a Rental Item</span>
                                        <button (click)="addRentalItem(rentalRequest.id)" mat-icon-button
                                            class="add-btn">
                                            <mat-icon>add</mat-icon>
                                        </button>
                                    </div>
                                </div>
                            </div>

                            <ng-container *ngIf="(isRentalItemsLoaded$ | async) == true; else rentalItemsLoading">
                                <ng-container *ngIf="rentalRequest.rentalItems?.length > 0; else noRentalItems">
                                    <ng-container *ngFor="let rentalItem of rentalRequest.rentalItems">
                                        <div class="rental-request-document-form-item">
                                            <div class="rental-request-document-form-item-action">
                                                <div class="rental-request-document-form-item-name">
                                                    {{rentalItem.friendlyName}} -
                                                    {{getRentalItemDisplayNameByValue(rentalItem.type)}} -
                                                    <mat-icon style="font-size: 18px; width: 16px;
                                                    height: 16px;">attach_money</mat-icon>{{rentalItem.price |
                                                    number:'2.'}}
                                                </div>
                                                <div>
                                                    <button mat-icon-button [matMenuTriggerFor]="menu">

                                                        <mat-icon>more_vert</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu">
                                                        <button class="action-form-remove-btn" mat-menu-item
                                                            (click)="removeRentalItem(rentalItem)">
                                                            <mat-icon>delete</mat-icon>
                                                            <span>Remove Rental</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-template #noRentalItems>
                                    <div class="dq-flex-centered no-item">No rental items yet!</div>
                                </ng-template>
                            </ng-container>
                            <ng-template #rentalItemsLoading>
                                <div class="dq-flex-centered">
                                    <mat-spinner style="margin: 30px auto;" diameter="48"></mat-spinner>

                                </div>
                            </ng-template>
                            <ng-container *ngIf="rentalRequest.rentalRequestFormFiles?.length > 0; else noForm">
                                <ng-container
                                    *ngIf="(isRentalRequestFormListLoading$ | async) == false; else formListLoading">
                                    <ng-container *ngFor="let file of rentalRequest.rentalRequestFormFiles">
                                        <div class="rental-request-document-form-item rental-document-status-created">
                                            <div class="rental-request-document-form-item-action">
                                                <div class="rental-request-document-form-item-name">{{file.fileName}}
                                                </div>
                                                <div>
                                                    <button mat-icon-button [matMenuTriggerFor]="menu">

                                                        <mat-icon>more_vert</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item
                                                            [disabled]="!(isDocumentViewer() | async) && !(isDocumentManager() | async)"
                                                            (click)="openRentalRequestUploadDocumentViewerDialog(file.id)">
                                                            <mat-icon>visibility</mat-icon>
                                                            <span>View Document</span>
                                                        </button>
                                                        <button class="action-form-remove-btn" mat-menu-item
                                                            [disabled]="isRentalRequestEditActionDisabled() || isRentalApplicationEditActionDisabled() || !(isDocumentManager() | async)"
                                                            (click)="openRemoveRentalRequestFormFileDialog(file)">
                                                            <mat-icon>delete</mat-icon>
                                                            <span>Remove Document</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-template #formListLoading>
                                    <div class="dq-flex-centered">
                                        <mat-spinner diameter="24"></mat-spinner>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </div>
                    </div>

                    <div class="rental-request-document-section dq-box-shadow">
                        <div class="rental-request-document-container">
                            <div class="rental-request-document-title-section contrast-offset-background-color">
                                <div class="dq-flex">
                                    <div>Leasing Documents</div>
                                </div>
                                <div class="dq-flex-centered">
                                    <ng-container *ngIf="canAddOfflineForm()">
                                        <div class="add-action dq-flex-centered">
                                            <span class="add-label">Upload Leasing File</span>
                                            <button
                                                (click)="openAddNewRentalRequestDocumentManualDialog(rentalRequest.id)"
                                                mat-icon-button class="add-btn">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="canAddOnlineForm()">
                                        <div class="add-action dq-flex-centered">
                                            <span class="add-label">Add a Schedule</span>
                                            <button (click)="openAddNewRentalRequestFormDialog(rentalRequest.id)"
                                                mat-icon-button class="add-btn">
                                                <mat-icon>add</mat-icon>
                                            </button>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                            <ng-container *ngIf="rentalRequest.rentalRequestForms?.length > 0; else noForm">
                                <ng-container
                                    *ngIf="(isRentalRequestFormListLoading$ | async) == false; else formListLoading">
                                    <ng-container *ngFor="let form of rentalRequest.rentalRequestForms">
                                        <div class="rental-request-document-form-item"
                                            [ngClass]="getLeasingStatusClass(form.id)">
                                            <div class="rental-request-document-form-item-action">
                                                <div class="rental-request-document-form-item-name">
                                                    {{form.friendlyName}} - {{getRentalFormTypeLabel(form.type)}}
                                                    <div class="rental-title-detail-chip">{{
                                                        getRentalFormStatusLabel(form.status)}}</div>
                                                </div>
                                                <div>
                                                    <button mat-icon-button [matMenuTriggerFor]="menu">

                                                        <mat-icon>more_vert</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item
                                                            [disabled]="!(isDocumentViewer() | async) && !(isDocumentManager() | async)"
                                                            (click)="openRentalRequestFormDocumentViewerDialog(form.id)">
                                                            <mat-icon>visibility</mat-icon>
                                                            <span>View Document</span>
                                                        </button>
                                                        <ng-container
                                                            *ngIf="rentalRequest.status == rentalRequestStatuses.APPROVED">
                                                            <button mat-menu-item
                                                                [disabled]="isRentalRequestEditActionDisabled() || !(isDocumentManager() | async)"
                                                                (click)="openLesingDocSignatureResettDialog(form.id)">
                                                                <mat-icon>refresh</mat-icon>
                                                                <span>Reset Signature</span>
                                                            </button>
                                                        </ng-container>
                                                        <button mat-menu-item
                                                            [disabled]="isRentalRequestEditActionDisabled() || !(isDocumentManager() | async)"
                                                            (click)="openRentalRequestEditorDialog(form.id)">
                                                            <mat-icon>edit</mat-icon>
                                                            <span>Edit Document</span>
                                                        </button>
                                                        <button class="action-form-remove-btn" mat-menu-item
                                                            [disabled]="isRentalRequestEditActionDisabled() || !(isDocumentManager() | async)"
                                                            (click)="openRemoveRentalRequestFormDialog(form)">
                                                            <mat-icon>delete</mat-icon>
                                                            <span>Remove Document</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                            </div>
                                            <div class="rental-request-document-form-item-signature">
                                                <ng-container *ngFor="let signature of form.signatures">
                                                    <div class="dq-flex-aligned">
                                                        <ng-container
                                                            *ngIf="signature?.signDate != null; else notSigned">
                                                            <mat-icon>create</mat-icon>
                                                            <span
                                                                class="dq-font-weight-700">{{signature?.applicantName}}</span>signed
                                                            the document on {{signature?.signDate | date: 'EEEE,
                                                            MMMd'}}.
                                                        </ng-container>
                                                        <ng-template #notSigned>
                                                            <mat-icon>block</mat-icon>
                                                            <span
                                                                class="dq-font-weight-700">{{signature?.applicantName}}</span>has
                                                            <span class="dq-font-weight-700">not</span>yet signed the
                                                            document.
                                                        </ng-template>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-template #formListLoading>
                                    <div class="dq-flex-centered">
                                        <mat-spinner diameter="24"></mat-spinner>
                                    </div>
                                </ng-template>
                            </ng-container>
                            <ng-container *ngIf="rentalRequest.rentalRequestFormFiles?.length > 0; else noForm">
                                <ng-container
                                    *ngIf="(isRentalRequestFormListLoading$ | async) == false; else formListLoading">
                                    <ng-container *ngFor="let file of rentalRequest.rentalRequestFormFiles">
                                        <div class="rental-request-document-form-item rental-document-status-created">
                                            <div class="rental-request-document-form-item-action">
                                                <div class="rental-request-document-form-item-name">{{file.fileName}}
                                                </div>
                                                <div>
                                                    <button mat-icon-button [matMenuTriggerFor]="menu">

                                                        <mat-icon>more_vert</mat-icon>
                                                    </button>
                                                    <mat-menu #menu="matMenu">
                                                        <button mat-menu-item
                                                            [disabled]="!(isDocumentViewer() | async) && !(isDocumentManager() | async)"
                                                            (click)="openRentalRequestUploadDocumentViewerDialog(file.id)">
                                                            <mat-icon>visibility</mat-icon>
                                                            <span>View Document</span>
                                                        </button>
                                                        <button class="action-form-remove-btn" mat-menu-item
                                                            [disabled]="isRentalRequestEditActionDisabled() || isRentalApplicationEditActionDisabled() || !(isDocumentManager() | async)"
                                                            (click)="openRemoveRentalRequestFormFileDialog(file)">
                                                            <mat-icon>delete</mat-icon>
                                                            <span>Remove Document</span>
                                                        </button>
                                                    </mat-menu>
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </ng-container>
                                <ng-template #formListLoading>
                                    <div class="dq-flex-centered">
                                        <mat-spinner diameter="24"></mat-spinner>
                                    </div>
                                </ng-template>
                            </ng-container>
                        </div>
                    </div>
                </ng-container>

                <div class="rental-application-section dq-box-shadow">
                    <ng-container *ngIf="(isRentalApplicationLoaded$ | async); else loading">
                        <ng-container *ngIf="rentalApplication$ | async as rentalApplication; else noApplication">
                            <div class="section-header">
                                <button class="rental-request-btn" mat-stroked-button
                                    [matMenuTriggerFor]="rentalApplicationMenu">
                                    <span>{{rentalApplication.applicantFirstName}}
                                        {{rentalApplication.applicantLastName}}</span>
                                    <mat-icon>keyboard_arrow_down</mat-icon>
                                </button>
                                <mat-menu #rentalApplicationMenu="matMenu">
                                    <ng-container
                                        *ngFor="let rentalApplicationListItem of (rentalApplicationListItems$ | async)">
                                        <button mat-menu-item
                                            (click)="onRentalApplicationChange(rentalApplicationListItem.id)">
                                            <ng-container *ngIf="rentalApplicationListItem.id == rentalApplication.id">
                                                <mat-icon>done</mat-icon>
                                            </ng-container>
                                            <span>{{rentalApplicationListItem.applicantFirstName}}
                                                {{rentalApplicationListItem.applicantLastName}} | {{
                                                rentalApplicationListItem.applicantType }} | {{
                                                getRentalApplicationStatusLabel(rentalApplicationListItem.status)
                                                }}</span>
                                        </button>
                                    </ng-container>
                                </mat-menu>
                                <div class="menu-action dq-flex-centered">
                                    <button mat-icon-button [matMenuTriggerFor]="menu">

                                        <mat-icon>more_vert</mat-icon>
                                    </button>
                                    <mat-menu #menu="matMenu">
                                        <div *ngIf="rentalApplication.status == rentalApplicationStatuses.CREATED">
                                            <button [disabled]="isRentalApplicationStarting$ | async" mat-menu-item
                                                (click)="startRentalApplication(false)">
                                                <mat-icon>play_circle_filled</mat-icon>
                                                <span>Start rental application</span>
                                            </button>
                                        </div>
                                        <div
                                            *ngIf="rentalApplication.status == rentalApplicationStatuses.CREATED || rentalApplication.status == rentalApplicationStatuses.IN_PROGRESS || rentalApplication.status == rentalApplicationStatuses.SUBMITTED_FOR_REVIEW">
                                            <button [disabled]="isRentalApplicationStarting$ | async" mat-menu-item
                                                (click)="updateApplicationInfo()">
                                                <mat-icon>edit</mat-icon>
                                                <span>Edit Applicant</span>
                                            </button>
                                        </div>
                                        <div
                                            *ngIf="rentalApplication.status != rentalApplicationStatuses.COMPLETED && rentalApplication.status != rentalApplicationStatuses.CANCELLED">
                                            <button [disabled]="isRentalFileUploadLinkSending$ | async" mat-menu-item
                                                (click)="sendRentalApplicationFileUploadLink(rentalApplication.id)">
                                                <mat-icon>cloud_upload</mat-icon>
                                                <span>Send Upload Link</span>
                                            </button>
                                        </div>
                                        <div *ngIf="rentalApplication.status != rentalApplicationStatuses.CREATED">
                                            <button mat-menu-item
                                                (click)="openResendApplicantEmail(rentalApplication.id)"
                                                [disabled]="isRentalApplicationResentInvitationDisabled()">
                                                <mat-icon>email</mat-icon>
                                                <span>Resend Application Link</span>
                                            </button>
                                        </div>
                                        <button mat-menu-item
                                            [disabled]="isRentalRequestEditActionDisabled() || isRentalApplicationEditActionDisabled()"
                                            (click)="openCancelApplicationDialog(rentalApplication.id, rentalApplication.applicantFirstName, rentalApplication.applicantLastName)"
                                            class="delete-application-btn">
                                            <mat-icon>cancel</mat-icon>
                                            Cancel / Decline
                                        </button>
                                    </mat-menu>
                                </div>
                            </div>
                            <mat-divider></mat-divider>
                            <div class="rental-application-container">
                                <div class="rental-application-info">
                                    <div class="rental-application-field-label"><span
                                            class="dq-font-weight-700 rental-application-big-info">{{getRentalApplicationStatusLabel(rentalApplication.status)}}</span>
                                    </div>
                                    <div class="rental-application-field-label">
                                        <span class="rental-application-big-info">
                                            {{rentalApplication.applicantFirstName}}
                                            <span
                                                class="dq-font-weight-700">({{getApplicationDetail(rentalApplication.applicantType)}}):
                                            </span>
                                            <span
                                                class="dq-font-weight-700">{{getLanguageLabel(rentalApplication.applicantLanguage)}}-speaking,
                                            </span>
                                            <span
                                                class="dq-font-weight-700">{{getRentalApplicationEmployementStatusLabel(rentalApplication.applicantEmploymentStatus)}}.</span>
                                        </span>
                                    </div>
                                    <div class="rental-request-field-label"><span
                                            class="dq-font-weight-700">{{rentalApplication.applicantEmail}} /
                                            {{rentalApplication.applicantPhoneNumber}}</span></div>
                                    <div class="rental-request-field-label">Created on <span
                                            class="dq-font-weight-700">{{rentalApplication.creationDate | date: 'EEEE,
                                            MMM d, y'}}</span></div>
                                </div>
                                <div class="rental-application-title-section contrast-offset-background-color">
                                    <div class="dq-flex">
                                        <div>Rental Application Documents</div>
                                    </div>
                                    <div class="dq-flex-centered">
                                        <ng-container *ngIf="canAddOnlineForm()">
                                            <div class="add-action dq-flex-centered">
                                                <span class="add-label">Add Document</span>
                                                <button
                                                    (click)="openAddNewRentalApplicationFormDialog(rentalApplication.id)"
                                                    mat-icon-button class="add-btn">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <ng-container
                                    *ngIf="filterRentalApplicationForms(rentalApplication.rentalApplicationForms)?.length > 0; else noForm">
                                    <ng-container
                                        *ngIf="(isRentalApplicationFormListLoading$ | async) == false; else formListLoading">
                                        <div *ngFor="let form of filterRentalApplicationForms(rentalApplication.rentalApplicationForms)"
                                            class="rental-application-form-item"
                                            [ngClass]="getFormStatusClass(form.id)">
                                            <div class="rental-application-form-item-name">{{form.friendlyName}} -
                                                {{getRentalFormTypeLabel(form.type)}}
                                                <div class="rental-title-detail-chip">{{
                                                    getRentalFormStatusLabel(form.status)}}</div>
                                            </div>
                                            <div>
                                                <button mat-icon-button [matMenuTriggerFor]="menu">

                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item
                                                        [disabled]="!(isDocumentViewer() | async) && !(isDocumentManager() | async)"
                                                        (click)="openRentalApplicationFormDocumentViewerDialog(form.id)">
                                                        <mat-icon>visibility</mat-icon>
                                                        <span>View Document</span>
                                                    </button>
                                                    <button mat-menu-item
                                                        [disabled]="isRentalRequestEditActionDisabled() || isRentalApplicationEditActionDisabled() || !(isDocumentManager() | async)"
                                                        (click)="openRentalApplicationEditorDialog(form.id)">
                                                        <mat-icon>edit</mat-icon>
                                                        <span>Edit Document</span>
                                                    </button>
                                                    <button class="action-form-remove-btn" mat-menu-item
                                                        [disabled]="isRentalRequestEditActionDisabled() || isRentalApplicationEditActionDisabled() || !(isDocumentManager() | async)"
                                                        (click)="openRemoveRentalApplicationFormDialog(form)">
                                                        <mat-icon>delete</mat-icon>
                                                        <span>Remove Document</span>
                                                    </button>
                                                </mat-menu>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #formListLoading>
                                        <div class="dq-flex-centered">
                                            <mat-spinner diameter="24"></mat-spinner>
                                        </div>
                                    </ng-template>
                                </ng-container>
                                <div class="rental-application-title-section contrast-offset-background-color">
                                    <div class="dq-flex">
                                        <div>Post Leasing Forms</div>
                                    </div>
                                    <div class="dq-flex-centered">
                                        <ng-container *ngIf="canAddOnlineForm()">
                                            <div class="add-action dq-flex-centered">
                                                <span class="add-label">Add Document</span>
                                                <button
                                                    (click)="openAddNewRentalApplicationAddtionalFormDialog(rentalApplication.id)"
                                                    mat-icon-button class="add-btn">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <ng-container
                                    *ngIf="filterLeasingAgreementAttionalForms(rentalApplication.rentalApplicationForms)?.length > 0; else noForm">
                                    <ng-container
                                        *ngIf="(isRentalApplicationFormListLoading$ | async) == false; else formListLoading">
                                        <div *ngFor="let form of filterLeasingAgreementAttionalForms(rentalApplication.rentalApplicationForms)"
                                            class="rental-application-form-item"
                                            [ngClass]="getFormStatusClass(form.id)">
                                            <div class="rental-application-form-item-name">{{form.friendlyName}} -
                                                {{getRentalFormTypeLabel(form.type)}}
                                                <div class="rental-title-detail-chip">{{
                                                    getRentalFormStatusLabel(form.status)}}</div>
                                            </div>
                                            <div>
                                                <button mat-icon-button [matMenuTriggerFor]="menu">

                                                    <mat-icon>more_vert</mat-icon>
                                                </button>
                                                <mat-menu #menu="matMenu">
                                                    <button mat-menu-item
                                                        [disabled]="!(isDocumentViewer() | async) && !(isDocumentManager() | async)"
                                                        (click)="openRentalApplicationFormDocumentViewerDialog(form.id)">
                                                        <mat-icon>visibility</mat-icon>
                                                        <span>View Document</span>
                                                    </button>
                                                    <button mat-menu-item
                                                        [disabled]="isRentalRequestEditActionDisabled() || isRentalApplicationEditActionDisabled() || !(isDocumentManager() | async)"
                                                        (click)="openRentalApplicationEditorDialog(form.id)">
                                                        <mat-icon>edit</mat-icon>
                                                        <span>Edit Document</span>
                                                    </button>
                                                    <button class="action-form-remove-btn" mat-menu-item
                                                        [disabled]="isRentalRequestEditActionDisabled() || isRentalApplicationEditActionDisabled() || !(isDocumentManager() | async)"
                                                        (click)="openRemoveRentalApplicationFormDialog(form)">
                                                        <mat-icon>delete</mat-icon>
                                                        <span>Remove Document</span>
                                                    </button>
                                                </mat-menu>
                                            </div>
                                        </div>
                                    </ng-container>
                                    <ng-template #formListLoading>
                                        <div class="dq-flex-centered">
                                            <mat-spinner diameter="24"></mat-spinner>
                                        </div>
                                    </ng-template>
                                </ng-container>
                                <div class="rental-application-title-section contrast-offset-background-color">
                                    <div class="dq-flex">
                                        <div>Verification Reports</div>
                                    </div>
                                    <div class="dq-flex-centered">
                                        <ng-container *ngIf="canAddCreditBackgroundSoftReport()">
                                            <div class="add-action dq-flex-centered">
                                                <span class="add-label">Add a Check</span>
                                                <button mat-icon-button class="add-btn"
                                                    (click)="openAddNewCreditBackgroundSoftReport(rentalApplication.id)">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <ng-container
                                    *ngIf="rentalApplication.rentalApplicationCertnReports?.length > 0; else noCreditBackgroundReport">
                                    <div *ngFor="let report of rentalApplication.rentalApplicationCertnReports"
                                        class="rental-application-form-item"
                                        [ngClass]="getCertnVerificationReportClass(report.id)">
                                        <div class="rental-application-form-item-name">{{getCheckReportName(report)}}
                                            <div class="rental-title-detail-chip">{{
                                                getRentalApplicationVerificationStatusLabel(report.reportStatus) }}
                                            </div>
                                        </div>
                                        <div>
                                            <button mat-icon-button [matMenuTriggerFor]="menu">

                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <ng-container
                                                    *ngIf="report.reportStatus == rentalFormStatuses.COMPLETED">
                                                    <button mat-menu-item
                                                        [disabled]="!(isDocumentViewer() | async) && !(isDocumentManager() | async)"
                                                        (click)="openCertnDocumentViewerDialog(report.id, report.reportType)">
                                                        <mat-icon>visibility</mat-icon>
                                                        <span>View Document</span>
                                                    </button>
                                                </ng-container>
                                                <button class="action-form-remove-btn"
                                                    (click)="openRemoveCertnVerificationReportDialog(report.id)"
                                                    mat-menu-item
                                                    [disabled]="isRentalRequestEditActionDisabled() || isRentalApplicationEditActionDisabled() || !(isDocumentManager() | async)">
                                                    <mat-icon>delete</mat-icon>
                                                    <span>Remove Document</span>
                                                </button>
                                            </mat-menu>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-container
                                    *ngIf="rentalApplication.rentalApplicationPlaidReports?.length > 0; else noIncomeAssetReport">
                                    <div *ngFor="let report of rentalApplication.rentalApplicationPlaidReports"
                                        class="rental-application-form-item"
                                        [ngClass]="getPlaidVerificationReportClass(report.id)">
                                        <div class="rental-application-form-item-name">{{ getCheckReportName(report) }}
                                            <div class="rental-title-detail-chip">{{
                                                getRentalApplicationVerificationStatusLabel(report.reportStatus) }}
                                            </div>
                                        </div>
                                        <div>
                                            <button mat-icon-button [matMenuTriggerFor]="menu">

                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <ng-container
                                                    *ngIf="report.reportStatus == rentalFormStatuses.COMPLETED">
                                                    <button mat-menu-item
                                                        [disabled]="!(isDocumentViewer() | async) && !(isDocumentManager() | async)"
                                                        (click)="openPlaidDocumentViewerDialog(report.id, report.reportType)">
                                                        <mat-icon>visibility</mat-icon>
                                                        <span>View Document</span>
                                                    </button>
                                                </ng-container>
                                                <button class="action-form-remove-btn"
                                                    (click)="openRemovePlaidVerificationReportDialog(report.id)"
                                                    mat-menu-item
                                                    [disabled]="isRentalRequestEditActionDisabled() || isRentalApplicationEditActionDisabled() || !(isDocumentManager() | async)">
                                                    <mat-icon>delete</mat-icon>
                                                    <span>Remove Document</span>
                                                </button>
                                            </mat-menu>
                                        </div>
                                    </div>
                                </ng-container>
                                <div class="rental-application-title-section contrast-offset-background-color">
                                    <div class="dq-flex">
                                        <div>Uploaded Rental Application Files</div>
                                    </div>
                                    <div class="dq-flex-centered">
                                        <ng-container *ngIf="canAddFile()">
                                            <div class="add-action dq-flex-centered">
                                                <span class="add-label">Upload File</span>
                                                <button
                                                    (click)="openAddNewRentalApplicationDocumentManualDialog(rentalApplication.id, rentalApplication.applicantFirstName, rentalApplication.applicantLastName)"
                                                    mat-icon-button class="add-btn">
                                                    <mat-icon>add</mat-icon>
                                                </button>
                                            </div>
                                        </ng-container>
                                    </div>
                                </div>
                                <ng-container
                                    *ngIf="rentalApplication.rentalApplicationFormFiles?.length > 0; else noFile">
                                    <div *ngFor="let file of rentalApplication.rentalApplicationFormFiles"
                                        class="rental-application-form-item rental-application-form-file">
                                        <div class="rental-application-form-item-name">{{file.fileName}}</div>
                                        <div>
                                            <button mat-icon-button [matMenuTriggerFor]="menu">

                                                <mat-icon>more_vert</mat-icon>
                                            </button>
                                            <mat-menu #menu="matMenu">
                                                <button mat-menu-item
                                                    [disabled]="!(isDocumentViewer() | async) && !(isDocumentManager() | async)"
                                                    (click)="openRentalApplicationUploadDocumentViewerDialog(file.id)">
                                                    <mat-icon>visibility</mat-icon>
                                                    <span>View Document</span>
                                                </button>
                                                <button class="action-form-remove-btn" mat-menu-item
                                                    [disabled]="isRentalRequestEditActionDisabled() || isRentalApplicationEditActionDisabled() || !(isDocumentManager() | async)"
                                                    (click)="openRemoveRentalApplicationFormFileDialog(file)">
                                                    <mat-icon>delete</mat-icon>
                                                    <span>Remove Document</span>
                                                </button>
                                            </mat-menu>
                                        </div>
                                    </div>
                                </ng-container>
                                <ng-template #noForm>
                                    <div class="dq-flex-centered no-item">No form selected yet!</div>
                                </ng-template>
                                <ng-template #noFile>
                                    <div class="dq-flex-centered no-item">No file uploaded yet!</div>
                                </ng-template>
                                <ng-template #noCreditBackgroundReport>
                                    <div class="dq-flex-centered no-item">No credit / background check report yet!</div>
                                </ng-template>
                                <ng-template #noIncomeAssetReport>
                                    <div class="dq-flex-centered no-item">No Income / Asset report yet!</div>
                                </ng-template>
                                <ng-template #noApplication>
                                    <div class="no-application-div">
                                        There is no application at the moment for this rental request.
                                    </div>
                                </ng-template>
                            </div>
                        </ng-container>
                    </ng-container>
                </div>
            </ng-container>
            <ng-template #noRentalRequest>
                <div class="no-request-div dq-box-shadow">
                    There is no rental request at the moment, please use the inventory to start a new one.
                </div>
            </ng-template>
        </ng-container>
        <ng-template #loading>
            <div class="spinner-div">
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>
    </div>
</div>