<div class="client-dialog-appointment-component dq-box-shadow">
    <div class="title-div">
        <h3 class="title">Appointments</h3>
    </div>
    <mat-divider></mat-divider>
    <div class="appointments-container">
        <ng-container *ngIf="(isAppointmentsLoaded$ | async); else loading">
            <ng-container *ngFor="let appointment of (appointments$ | async)">
                <header class="header">
                    <div class="header-meta">
                        <div class="appointment-title">
                            <mat-icon class="timeline-icon date_range">date_range</mat-icon>
                            <strong>
                                Appointment with {{appointment.representativeName}} on {{appointment.startTime | date:
                                'MMM d, y, h:mm a'}} -
                                {{appointment.timeZone}}
                            </strong>
                        </div>
                        <ng-container
                            *ngIf="appointment.appointmentStatusType == 'InProgress' || appointment.appointmentStatusType == 'InApplication'">
                            <div class="button-div">
                                <ng-container *ngIf="(hasAccessToBooking() | async)">

                                    <button mat-stroked-button
                                        (click)="openBookAppointmentDialog(appointment)">Edit</button>
                                    <span>&nbsp;</span>
                                </ng-container>

                                <button mat-stroked-button class="appointment-cancel-button error-stroked-contrast-40"
                                    (click)="cancelAppointment(appointment)">Cancel</button>


                            </div>
                        </ng-container>
                    </div>
                </header>
                <div class="app-detail"
                    [ngClass]="{'app-win': appointment.appointmentStatusType === 'Won' || appointment.appointmentStatusType === 'InProgress', 'app-lost' : appointment.appointmentStatusType === 'Lost' || appointment.appointmentStatusType === 'Terminated', 'app-in-application' : appointment.appointmentStatusType === 'InApplication' }">
                    <ng-container *ngIf="appointment.appointmentStatusType == 'InApplication'">
                        <div class="app-detail-item">Appointment Status : <span class="app-detail-item-value">Hot</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="appointment.appointmentStatusType == 'InProgress'">
                        <div class="app-detail-item">Appointment Status : <span
                                class="app-detail-item-value">Active</span></div>
                    </ng-container>
                    <ng-container *ngIf="appointment.appointmentStatusType == 'Terminated'">
                        <div class="app-detail-item">Appointment Status : <span class="app-detail-item-value">Terminated
                                / Canceled
                                <ng-container *ngIf="appointment.lastUpdateBy">
                                    By {{appointment.lastUpdateBy}}
                                </ng-container>
                            </span></div>
                    </ng-container>
                    <ng-container *ngIf="appointment.appointmentStatusType == 'Won'">
                        <div class="app-detail-item">Appointment Status : <span class="app-detail-item-value">Won</span>
                        </div>
                        <div class="app-detail-item">Winning price: <span
                                class="app-detail-item-value">${{appointment.winPrice | number:'2.'}}</span></div>
                        <div class="app-detail-item">Closing date:
                            <span class="app-detail-item-value">{{appointment.closingDate | date: 'MMM d, y'}}</span>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="appointment.appointmentStatusType == 'Lost'">
                        <div class="app-detail-item">Appointment Status : <span
                                class="app-detail-item-value">Lost</span></div>
                        <div class="app-detail-item">Lost reason: <span
                                class="app-detail-item-value">{{appointment.lostReason}}</span></div>
                        <div class="app-detail-item">Lost comment: <span
                                class="app-detail-item-value">{{appointment.lostComments}}</span></div>
                        <div class="app-detail-item">Lost date: <span
                                class="app-detail-item-value">{{appointment.closingDate| date: 'MMM d, y'}}</span></div>
                    </ng-container>
                    <div class="app-detail-item">Building Name : <span
                            class="app-detail-item-value">{{appointment.buildingName}}</span></div>
                    <div class="app-detail-item">Building Address : <span
                            class="app-detail-item-value">{{appointment.buildingAddress}}</span></div>
                    <div class="app-detail-item">Move In Date: <span  class="app-detail-item-value">{{appointment.moveInDate | date: 'MMM d, y'}}</span>
                    </div>
                    <div class="app-detail-item">Bed Preference: <span
                        class="app-detail-item-value">{{formatOptionsPreference(bedOptions, appointment.bedPreference)}}</span>
                    </div>
                    <div class="app-detail-item">Bath Preference: <span
                        class="app-detail-item-value">{{formatOptionsPreference(bathOptions, appointment.bathPreference)}}</span>
                    </div>
                    <div class="app-detail-item">Parking Preference: <span
                        class="app-detail-item-value">{{formatParkingOptionsPreference(parkingOptions, appointment.parkingPreference)}}</span>
                    </div>
                    <div class="app-detail-item">Pet Preference: <span
                        class="app-detail-item-value">{{formatOptionsPreference(petOptions, appointment.petPreference)}}</span>
                    </div>
                    <div class="app-detail-item">Budget: <span class="app-detail-item-value">{{appointment.budget | number: '2.'}}</span></div>

                    <div class="app-detail-item">Note: <span class="app-detail-item-value">{{appointment.note}}</span>
                    </div>
                </div>
                <mat-divider></mat-divider>
            </ng-container>
            <ng-container *ngIf="(appointments$ | async).length == 0">
                <div class="no-appointment-div">
                    No Appointment
                </div>
            </ng-container>
        </ng-container>
        <ng-template #loading>
            <div class="spinner-div">
                <mat-spinner></mat-spinner>
            </div>
        </ng-template>
    </div>
</div>