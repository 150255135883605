<div class="action-dialog-container">
    <button class="close-btn" mat-icon-button (click)="close()">
        <mat-icon svgIcon="times" class="icon cancel"></mat-icon>
    </button>
    <div class="action-dialog-title">
        <h2>{{ data.title }}</h2>
    </div>
    <div class="action-dialog-content">
        <div class="action-div">
            {{notificationText}}
        </div>
    </div>
    <ng-container *ngIf="actionValidationMessages$ | async as actionValidationMessages">
        <ng-container
            *ngIf="actionValidationMessages.validationMessages?.length > 0 || actionValidationMessages.errorMessage != ''">
            <div class="validation-message-container">
                <div class="message-title">{{actionValidationMessages.errorMessage}}</div>
                <div *ngFor="let message of actionValidationMessages.validationMessages" class="validation-message">
                    {{message}}</div>
            </div>
        </ng-container>
    </ng-container>
    <div class="row">
        <div class="col-12 button-div">
            <dq-spinner-button class="generic-button confirm-button" type="button" (click)="onAction()"
                [options]="spinnerButtonOptions"></dq-spinner-button>
        </div>
    </div>
</div>