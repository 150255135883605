import { Injectable } from '@angular/core';
import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {

  getCssVariable(variableName: string): string {
    let theme = document.querySelector('.theme-wrapper');
    return getComputedStyle(theme).getPropertyValue(variableName).trim();
  }

  getM3TimePickerTheme(): NgxMaterialTimepickerTheme {
    return {
      container: {
        bodyBackgroundColor: this.getCssVariable('--sys-surface'),
        buttonColor: this.getCssVariable('--sys-primary'),
      },
      dial: {
        dialBackgroundColor: this.getCssVariable('--sys-primary'),
      },
      clockFace: {
        clockFaceBackgroundColor: this.getCssVariable('--sys-surface-container-highest'),
        clockHandColor: this.getCssVariable('--sys-primary'),
        clockFaceTimeInactiveColor: this.getCssVariable('--sys-on-surface'),
      },
    };
  }
}