import { createAction, props } from "@ngrx/store";
import { TimeZone } from "app/shared/models/timezone.model";
import { RepresentativeThumbnail, BookingEvent, BookingTimeSlot, LeadSource, BuildingProperty } from "../../booking.state";
import { LeadPreference } from "app/shared/models/lead-preference.model";

export const actionBookingLeadPreferenceRequested = createAction(
  '[Booking] Booking Lead Preference Requested',
  props<{ leadId: number }>()
)

export const actionBookingLeadPreferenceLoaded = createAction(
  '[Booking] Booking Lead Preference Loaded',
  props<{ preferences: LeadPreference[] }>()
)

export const actionBookingLeadPreferenceFailed = createAction(
  '[Booking] Booking Lead Preference Failed'
)

export const actionBookingEventCalendarSelectedIdUpdate = createAction(
  '[Booking] Booking Event Calendar Selected Id Update',
  props<{ calendarId: number }>()
);

export const actionBookingEventCalendarRequested = createAction(
  '[Booking] Booking Event Calendar Requested',
  props<{ repUserId: number, calendarId: number }>()
);

export const actionBookingEventCalendarLoaded = createAction(
  '[Booking] Booking Event Calendar Loaded',
  props<{ representative: RepresentativeThumbnail, event: BookingEvent, buildingId: number, buildingProperties: BuildingProperty[] }>()
);

export const actionBookingEventCalendarAvaliablitiesRequested = createAction(
  '[Booking] Booking Event Calendar Avaliabilities Requested',
  props<{ repUserId: number, calendarId: number, bookingDate: string, timeZone: string }>()
);

export const actionBookingEventCalendarAvaliablitiesLoaded = createAction(
  '[Booking] Booking Event Calendar Avaliablities Loaded',
  props<{ timeSlots: BookingTimeSlot[] }>()
);

export const actionBookingEventCalendarLeadSourcesRequested = createAction(
  '[Booking] Booking Event Calendar Lead Sources Requested',
);

export const actionBookingEventCalendarLeadSourcesLoaded = createAction(
  '[Booking] Booking Event Calendar Lead Sources Loaded',
  props<{ leadSources: LeadSource[] }>()
);

export const actionBookingEventCalendarLeadSourcesFailed = createAction(
  '[Booking] Booking Event Calendar Lead Sources Failed',
);

export const actionBookingEventOverwriteCalendarAvaliablitiesRequested = createAction(
  '[Booking] Booking Event Overwrite Calendar Avaliabilities Requested',
  props<{ repUserId: number, calendarId: number, bookingDate: string, timeZone: string }>()
);

export const actionBookingEventOverwriteCalendarAvaliablitiesLoaded = createAction(
  '[Booking] Booking Event Overwrite Calendar Avaliablities Loaded',
  props<{ timeSlots: BookingTimeSlot[] }>()
);

export const actionBookingEventCalendarFailed = createAction(
  '[Booking] Booking Event Calendar Failed',
);

export const actionBookingEventCalendarAvaliablitiesFailed = createAction(
  '[Booking] Booking Event Calendar Avaliablities Failed',
);

export const actionBookingEventCalendarReset = createAction(
  '[Booking] Booking Event Calendar Reset',
);

export const actionBookingEventCalendarDateChange = createAction(
  '[Booking] Booking Event Calendar Date Change',
  props<{ date: string }>()
);

export const actionBookingEventCalendarTimeChange = createAction(
  '[Booking] Booking Event Calendar Time Change',
  props<{ time: BookingTimeSlot }>()
);

export const actionBookingEventCalendarToggleScheduleOverwrite = createAction(
  '[Booking] Booking Event Calendar Toggle Schedule Overwrite',
  props<{ isScheduleOverwrite: boolean }>()
)

export const actionBookingEventCalendarToggleIsFormActive = createAction(
  '[Booking] Booking Event Calendar Toggle Is Form Active',
  props<{ isFormActive: boolean }>()
)

export const actionBookingEventCalendarTimeIdToConfirmChange = createAction(
  '[Booking] Booking Event Calendar Time Slot Id To Confirm Change',
  props<{ id: string }>()
);

export const actionBookingEventCalendarCreateAppointment = createAction(
  '[Booking] Booking Event Calendar Create Appointement',
  props<{ formType: string, form: any }>()
);

export const actionBookingEventCalendarCreateAppointmentSuccess = createAction(
  '[Booking] Booking Event Calendar Create Appointement Success',
);

export const actionBookingEventCalendarCreateAppointmentFailed = createAction(
  '[Booking] Booking Event Calendar Create Appointement Failed',
);

export const actionBookingEventCalendarBookingFormBuildingAddressChange = createAction(
  '[Booking] Booking Event Calendar Booking Form Building Address Change',
  props<{ buildingAddress: string }>()
);

export const actionBookingEventCalendarChangeTimeZone = createAction(
  '[Booking] Booking Event Calendar Time Zone Changed',
  props<{ timeZone: TimeZone }>()
);

export const actionBookingEventCalendarChangeMinDate = createAction(
  '[Booking] Booking Event Calendar Min Date Changed',
  props<{ minDate: Date }>()
);

export const bookingEventCalendarActions = {
  actionBookingLeadPreferenceLoaded,
  actionBookingEventCalendarSelectedIdUpdate,
  actionBookingEventCalendarRequested,
  actionBookingEventCalendarLoaded,
  actionBookingEventCalendarAvaliablitiesRequested,
  actionBookingEventCalendarAvaliablitiesLoaded,
  actionBookingEventOverwriteCalendarAvaliablitiesRequested,
  actionBookingEventOverwriteCalendarAvaliablitiesLoaded,
  actionBookingEventCalendarToggleScheduleOverwrite,
  actionBookingEventCalendarDateChange,
  actionBookingEventCalendarTimeChange,
  actionBookingEventCalendarTimeIdToConfirmChange,
  actionBookingEventCalendarCreateAppointment,
  actionBookingEventCalendarCreateAppointmentSuccess,
  actionBookingEventCalendarChangeTimeZone,
  actionBookingEventCalendarReset,
  actionBookingEventCalendarChangeMinDate,
  actionBookingEventCalendarLeadSourcesRequested,
  actionBookingEventCalendarLeadSourcesLoaded,
  actionBookingEventCalendarBookingFormBuildingAddressChange,
  actionBookingEventCalendarToggleIsFormActive
}

export const bookingEventCalendarFailedActions = {
  actionBookingEventCalendarFailed,
  actionBookingEventCalendarAvaliablitiesFailed,
  actionBookingEventCalendarCreateAppointmentFailed,
  actionBookingEventCalendarLeadSourcesFailed
}

