<div class="tenant-dialog-quick-text-message-component">
  <div class="desktop-container">
    <ng-container [formGroup]="formText">
      <div class="row">
        <div class="phone-numbers-container">
          <button matTooltipPosition="left" matTooltipClass="link-tip" class="mat-menu-toggle-button" mat-stroked-button
            [matMenuTriggerFor]="fromPhoneNumbers">
            <mat-icon class="filter-icon phone-icon" svgIcon="phone"></mat-icon>
            <span class="d-none d-xl-inline">
              From: {{getCurrentFromNumberDisplayLabel()}}
            </span>
            
          </button>
          <mat-menu class="phone-numbers-panel" #fromPhoneNumbers="matMenu" yPosition="above">
            <ng-container *ngFor="let fromPhoneNumber of (sendFromPhoneNumbers$ | async)">
              <button mat-menu-item class="d-flex flex-wrap flex-column"
                [ngStyle]="{'background-color': fromPhoneNumber.phoneNumber == (lastCommunicationPhoneNumber$ | async) ? '#d3f4d6' : '#FFFFFF'}"
                (click)="updateFromNumber(fromPhoneNumber.id)">
                {{getFromNumberDisplayLabel(fromPhoneNumber)}}
              </button>
            </ng-container>
          </mat-menu>
        </div>
        <div class="phone-numbers-container">
          <button matTooltipPosition="left" matTooltipClass="link-tip" class="mat-menu-toggle-button" mat-stroked-button
            [matMenuTriggerFor]="toPhoneNumbers">
            <mat-icon class="filter-icon phone-icon" svgIcon="phone"></mat-icon>
            <span class="d-none d-xl-inline">
              To {{formText.get('sendTo').value}}
            </span>
            
          </button>
          <mat-menu class="phone-numbers-panel" #toPhoneNumbers="matMenu" yPosition="above">
            <ng-container *ngFor="let toPhoneNumber of toPhoneNumberOptions">
              <button mat-menu-item class="d-flex flex-wrap flex-column" (click)="updateToNumber(toPhoneNumber)">
                {{toPhoneNumber}}
              </button>
            </ng-container>
          </mat-menu>
        </div>
      </div>
      <div class="tenant-profile-templates-container">
        <button mat-mini-fab class="template-edit-button" (click)="openTextTemplateEditDialogEdit()">
          <mat-icon>edit</mat-icon>
        </button>
        <button matTooltipPosition="left" matTooltipClass="link-tip" class="m-2 mat-menu-toggle"
          mat-stroked-button [matMenuTriggerFor]="templates">
          <span class="d-xl-inline">
            Templates
          </span>
          
        </button>
        <mat-menu class="tenant-dialog-text-templates-menu" #templates="matMenu">
          <div class="d-flex flex-wrap flex-column">
            <div class="search-div search-input" (click)="$event.stopPropagation()">
              <mat-icon class="search-icon">search</mat-icon>
              <input class="search-input" type="text" placeholder="Search" [formControl]="templateSerachControl">
            </div>
            <ng-container *ngIf="(isTemplatesLoaded$ | async); else templatesLoading">
              <ng-container *ngFor="let template of (messageTemplateSearchOptions$ | async)">
                <div class="template-item" mat-menu-item (click)="applyTemplate(template)">
                  <span>{{template.displayName}}</span>
                </div>
              </ng-container>
            </ng-container>
            <ng-template #templatesLoading>
              <mat-spinner class="tenant-dialog-text-templates-loading-spinner"></mat-spinner>
            </ng-template>
          </div>
        </mat-menu>
      </div>
      <div class="text-message-content-div">
        <div class="text-message-conainer">
          <editor #tinyMce [apiKey]="tinyMceApiKey" [init]="editorSettings" formControlName="message"></editor>
        </div>
        <div class="footer-send-text">
          <button class="clear-button" mat-stroked-button (click)="resetMessage()">
            <mat-icon>clear_all</mat-icon> Clear
          </button>
          <dq-spinner-button class="operate-button" type="button" (click)="sendMessage()"
            [options]="spinnerSaveButtonOptions"></dq-spinner-button>
        </div>
      </div>
    </ng-container>
  </div>
</div>
