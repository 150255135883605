import { Injectable } from '@angular/core';
import * as signalR from "@microsoft/signalr"
import { Store } from '@ngrx/store';
import { actionUnifiedInboxMarkConversationUnreadSuccess, actionUnifiedInboxNewConversationNotificationLoaded } from 'app/features/unified-inbox/components/unified-inbox-conversation/unified-inbox-conversation.action';
import { actionUnifiedInboxAddActivityReceived } from 'app/features/unified-inbox/components/unified-inbox-main-panel/components/unified-inbox-activities/unified-inbox-activities.action';
import { actionUnifiedInboxAddTextMessageReceived } from 'app/features/unified-inbox/components/unified-inbox-main-panel/components/unified-inbox-lead-text-message/unified-inbox-lead-text-message.action';
import { environment } from 'environments/environment';
import { interval } from 'rxjs';
import { AuthService } from '../auth/auth.service';
import { NotificationService } from '../core.module';
import { actionTaskNotificationCountFailed, actionTaskNotificationCountSuccess, actionTaskNotificationCountUpdateSuccess, actionUserLeadConversationCountSuccess, actionUserTenantConversationCountSuccess, actionUserUpdateConnectionState } from '../settings/settings.actions';
import { actionTenantUnifiedInboxMarkConversationUnreadSuccess, actionTenantUnifiedInboxNewConversationNotificationLoaded } from 'app/features/tenant-unified-inbox/components/tenant-unified-inbox-conversation/tenant-unified-inbox-conversation.action';
import { actionTenantUnifiedInboxAddTextMessageReceived } from 'app/features/tenant-unified-inbox/components/tenant-unified-inbox-main-panel/components/tenant-unified-inbox-text-message/tenant-unified-inbox-text-message.action';
import { actionTenantUnifiedInboxAddActivityReceived } from 'app/features/tenant-unified-inbox/components/tenant-unified-inbox-main-panel/components/tenant-unified-inbox-activities/tenant-unified-inbox-activities.action';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {

  private hubConnection: signalR.HubConnection;
  private interval: any;

  constructor(private authService: AuthService,
    private notificationService: NotificationService,
    private store: Store) {
  }

  public startConnection = (token: string) => {

    if (token != undefined) {

      if (this.hubConnection?.state == signalR.HubConnectionState.Connected) {
        this.hubConnection.stop();
      }

      const options: signalR.IHttpConnectionOptions = {
        accessTokenFactory: () => {
          return token;
        }
      };

      this.hubConnection = new signalR.HubConnectionBuilder()
        .withUrl(`${environment.hubBaseUrl}platform?access_token=${token}`, options)
        .withAutomaticReconnect()
        .configureLogging(signalR.LogLevel.Information)
        .build();

      this.hubConnection
        .start()
        .then(() => {
          console.log('Connection started');
          this.getTaskCount();
          this.getUserConversationCount();
          this.store.dispatch(actionUserUpdateConnectionState({ connectionState: this.hubConnection?.state }))
        })
        .catch(err => console.log('Error while starting connection: ' + err))
    }

    //Update the connection state
    clearInterval(this.interval);
    this.interval = interval(5000).subscribe(x => {
      this.store.dispatch(actionUserUpdateConnectionState({ connectionState: this.hubConnection?.state }))
    });

    this.hubConnection.onclose(() => {
      console.log('Connection closed');
    });
  }

  public stopConnection = () => {
    if (this.hubConnection?.state == signalR.HubConnectionState.Connected) {
      this.hubConnection.stop();
    }
  }

  public getConnectionState() {
    return this.hubConnection?.state;
  }

  public turnOnTaskNotifyListener = () => {
    this.hubConnection?.on('taskNotify', (data) => {
      this.store.dispatch(actionTaskNotificationCountUpdateSuccess({ taskCount: data }));
    });
  }

  public turnOffTaskNotifyListener = () => {
    this.hubConnection?.off('taskNotify');
  }

  public turnOnAddTextMessageNotifyListener = () => {
    this.hubConnection?.on('createTextMessageNotify', (data) => {
      if (data != undefined || data != null) {
        this.store.dispatch(actionUnifiedInboxAddTextMessageReceived({ textMessage: data }));
      }
    });
  }

  public turnOffAddTextMessageNotifyListener = () => {
    this.hubConnection?.off('createTextMessageNotify');
  }

  public turnOnAddTenantTextMessageNotifyListener = () => {
    this.hubConnection?.on('createTenantTextMessageNotify', (data) => {
      if (data != undefined || data != null) {
        this.store.dispatch(actionTenantUnifiedInboxAddTextMessageReceived({ textMessage: data }));
      }
    });
  }

  public turnOffAddTenantTextMessageNotifyListener = () => {
    this.hubConnection?.off('createTenantTextMessageNotify');
  }

  public turnOnAddNoteNotifyListener = () => {
    this.hubConnection?.on('createLeadNoteNotify', (data) => {
      if (data != undefined || data != null) {
        this.store.dispatch(actionUnifiedInboxAddActivityReceived({ activity: data }));
      }
    });
  }

  public turnOffAddNoteNotifyListener = () => {
    this.hubConnection?.off('createLeadNoteNotify');
  }

  public turnOnAddTenantNoteNotifyListener = () => {
    this.hubConnection?.on('createTenantNoteNotify', (data) => {
      if (data != undefined || data != null) {
        this.store.dispatch(actionTenantUnifiedInboxAddActivityReceived({ activity: data }));
      }
    });
  }

  public turnOffAddTenantNoteNotifyListener = () => {
    this.hubConnection?.off('createTenantNoteNotify');
  }

  public turnOnAddCommunicationNotifyListener = () => {
    this.hubConnection?.on('createCommunicationNotify', (data) => {
      if (data != undefined || data != null) {
        this.store.dispatch(actionUnifiedInboxAddActivityReceived({ activity: data }));
      }
    });
  }

  public turnOffAddCommunicationNotifyListener = () => {
    this.hubConnection?.off('createCommunicationNotify');
  }

  public turnOnAddTenantCommunicationNotifyListener = () => {
    this.hubConnection?.on('createTenantCommunicationNotify', (data) => {
      if (data != undefined || data != null) {
        this.store.dispatch(actionTenantUnifiedInboxAddActivityReceived({ activity: data }));
      }
    });
  }

  public turnOffAddTenantCommunicationNotifyListener = () => {
    this.hubConnection?.off('createTenantCommunicationNotify');
  }

  public turnOnAddConversationNotifyListener = () => {
    this.hubConnection?.on('createConversationNotify', (data) => {
      if (data != undefined || data != null) {
        this.store.dispatch(actionUnifiedInboxNewConversationNotificationLoaded({ conversation: data }));
      }
    });
  }

  public turnOffAddConversationNotifyListener = () => {
    this.hubConnection?.off('createConversationNotify');
  }

  public turnOnAddTenantConversationNotifyListener = () => {
    this.hubConnection?.on('createTenantConversationNotify', (data) => {
      if (data != undefined || data != null) {
        this.store.dispatch(actionTenantUnifiedInboxNewConversationNotificationLoaded({ conversation: data }));
      }
    });
  }

  public turnOffAddTenantConversationNotifyListener = () => {
    this.hubConnection?.off('createTenantConversationNotify');
  }

  public turnOnConversationCountNotifyListener = () => {
    this.hubConnection?.on('conversationCountNotify', (data) => {
      this.store.dispatch(actionUserLeadConversationCountSuccess({ conversationCount: data }));
    });
  }

  public turnOffConversationCountNotifyListener = () => {
    this.hubConnection?.off('conversationCountNotify');
  }

  public turnOnTenantConversationCountNotifyListener = () => {
    this.hubConnection?.on('conversationTenantCountNotify', (data) => {
      this.store.dispatch(actionUserTenantConversationCountSuccess({ conversationCount: data }));
    });
  }

  public turnOffTenantConversationCountNotifyListener = () => {
    this.hubConnection?.off('conversationTenantCountNotify');
  }


  public turnOnConversationUnreadNotifyListener = () => {
    this.hubConnection?.on('markMessageUnread', (data) => {
      this.store.dispatch(actionUnifiedInboxMarkConversationUnreadSuccess({ conversationId: data }));
    });
  }

  public turnOffConversationUnreadNotifyListener = () => {
    this.hubConnection?.off('markMessageUnread');
  }

  public turnOnTenantConversationUnreadNotifyListener = () => {
    this.hubConnection?.on('markTenantMessageUnread', (data) => {
      this.store.dispatch(actionTenantUnifiedInboxMarkConversationUnreadSuccess({ conversationId: data }));
    });
  }

  public turnOffTenantConversationUnreadNotifyListener = () => {
    this.hubConnection?.off('markTenantMessageUnread');
  }

  public getTaskCount = () => {
    this.hubConnection.invoke('GetTaskCount')
      .then((data) => {
        this.store.dispatch(actionTaskNotificationCountSuccess({ taskCount: data }));
      })
      .catch(err => this.store.dispatch(actionTaskNotificationCountFailed()));
  }

  public getUserConversationCount = () => {
    this.hubConnection.invoke('GetUserConversationCount')
      .then((data) => {
        this.store.dispatch(actionUserLeadConversationCountSuccess({ conversationCount: data }));
      })
      .catch(err => console.log(err));
  }

  ngOnDestroy() {
    console.log('ncleaning up connection state');
    clearInterval(this.interval);
  }
}