import { DatePipe } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Inject,
  OnDestroy,
  OnInit,
  ViewEncapsulation
} from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { State } from 'app/features/tenant-dialog/tenant-dialog.state';
import { DeleteOrRemoveConfirmService } from 'app/shared/components/delete-remove-confirm/delete-or-remove-confirm.service';
import {
  Task,
  TASK_REMINDER_TIME_BEFORE_OPTION,
  TASK_REMINDER_TYPES,
  TASK_TYPES,
  TaskAssignUser,
  TaskReminderTimeBeforeOption,
  TaskReminderType,
  TaskType,
  TaskUpdateParam
} from 'app/shared/models/task.model';
import { TIME_ZONES, TimeZone } from 'app/shared/models/timezone.model';

import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { actionTenantDialogTasktaskUpdateRequested, tenantDialogTaskActions } from '../../tenant-dialog-task.action';
import {
  selectTenantDialogTaskAssignUsers,
  selectTenantDialogTaskIsTaskDeletedSuccessfully,
  selectTenantDialogTaskIsTaskDeleting,
  selectTenantDialogTaskIsTaskEditing,
  selectTenantDialogTaskIsTaskEditSuccessfully
} from '../../tenant-dialog-task.selector';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';
import { ThemeService } from 'app/shared/services/theme.service';

@Component({
  selector: 'dq-tenant-dialog-task-edit-dialog',
  templateUrl: './tenant-dialog-task-edit-dialog.component.html',
  styleUrls: ['./tenant-dialog-task-edit-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TenantDialogTaskEditDialogComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<TenantDialogTaskEditDialogComponent>,
    private cdr: ChangeDetectorRef,
    private datePipe: DatePipe,
    private themeService: ThemeService,
    @Inject(MAT_DIALOG_DATA) public data: Task,
    private deleteConfirmService: DeleteOrRemoveConfirmService
  ) {
    if (this.data) {
      this.task = data
      console.log(this.task)
    }
  }

  dashQTheme: NgxMaterialTimepickerTheme;

  spinnerSaveButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Save',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  unsubscribe: Subject<void> = new Subject();

  task: Task

  taskTypes: TaskType[] = TASK_TYPES
  taskReminderTypes: TaskReminderType[] = TASK_REMINDER_TYPES
  taskReminderTimeBeforeOptions: TaskReminderTimeBeforeOption[] = TASK_REMINDER_TIME_BEFORE_OPTION

  isEditing$: Observable<boolean>
  isEditdSuccessfully$: Observable<boolean>
  isDeleteSuccessfully$: Observable<boolean>

  taskAssignUsers$: Observable<TaskAssignUser[]>

  taskEditForm: FormGroup

  today: Date = new Date()

  timeZoneOptions: TimeZone[] = TIME_ZONES

  ngOnInit(): void {
    this.dashQTheme = this.themeService.getM3TimePickerTheme();
    this.taskEditForm = this.formBuilder.group({
      taskId: new FormControl(this.task.id, [Validators.required]),
      taskName: new FormControl(this.task.name, [Validators.required]),
      taskType: new FormControl(this.task.type, [Validators.required]),
      date: new FormControl(this.task.date, [Validators.required]),
      timeZone: new FormControl(this.task.timeZone, [Validators.required]),
      time: new FormControl(this.datePipe.transform(this.task.date, 'h:mm a'), [Validators.required]),
      taskReminderType: new FormControl(this.task.reminderType, [Validators.required]),
      taskReminderTimeBefore: new FormControl(this.task.reminderTimeBefore, [Validators.required]),
    })
    this.isEditing$ = this.store.pipe(select(selectTenantDialogTaskIsTaskEditing))
    this.isEditdSuccessfully$ = this.store.pipe(select(selectTenantDialogTaskIsTaskEditSuccessfully))
    this.isDeleteSuccessfully$ = this.store.pipe(select(selectTenantDialogTaskIsTaskDeletedSuccessfully))
    this.taskAssignUsers$ = this.store.pipe(select(selectTenantDialogTaskAssignUsers))

    this.isEditing$.pipe(takeUntil(this.unsubscribe)).subscribe(isEditing => {
      this.spinnerSaveButtonOptions.active = isEditing
      this.cdr.markForCheck()
    })

    this.isEditdSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isEditdSuccessfully => {
      if (isEditdSuccessfully) {
        this.dialogRef.close(true);
      }
    })
    this.isDeleteSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isDeleteSuccessfully => {
      if (isDeleteSuccessfully) {
        this.dialogRef.close(true);
      }
    })

  }

  save() {
    if (this.spinnerSaveButtonOptions.active) {
      return
    }
    this.taskEditForm.markAllAsTouched()
    if (this.taskEditForm.invalid) {
      return
    }
    let param: TaskUpdateParam = {
      id: this.taskEditForm.get('taskId').value,
      taskName: this.taskEditForm.get('taskName').value,
      taskType: this.taskEditForm.get('taskType').value,
      date: this.taskEditForm.get('date').value,
      time: this.taskEditForm.get('time').value,
      timeZone: this.taskEditForm.get('timeZone').value,
      taskReminderType: this.taskEditForm.get('taskReminderType').value,
      taskReminderTimeBefore: this.taskEditForm.get('taskReminderTimeBefore').value,
    }
    this.store.dispatch(actionTenantDialogTasktaskUpdateRequested({ param }))
  }

  cancel() {
    this.dialogRef.close(false);
  }


  close() {
    this.dialogRef.close(false);
  }

  delete() {
    this.deleteConfirmService.openDeleteConfrimDialog(
      `Delete task ${this.task.name}`,
      '',
      'Delete',
      'warn',
      this.store.pipe(select(selectTenantDialogTaskIsTaskDeleting)),
      this.store.pipe(select(selectTenantDialogTaskIsTaskDeletedSuccessfully)),
      tenantDialogTaskActions.actionTenantDialogTasktaskDeleteRequested({ taskId: this.task.id }),
      [tenantDialogTaskActions.actionTenantDialogTaskTaskDeleteStateReset()]
    )
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(tenantDialogTaskActions.actionTenantDialogTaskTaskEditStateReset())
  }


}
