<div class="client-dialog-container">
  <div class="dialog-container">
    <div mat-dialog-title class="mat-dialog-title">
      <div class="right-button-div">
        <div class="refresh-container" (click)="refreshDialog()">
          <mat-icon class="icon refresh-button">refresh</mat-icon>
        </div>
        <mat-icon (click)="$event.preventDefault();close()" svgIcon="times" class="icon cancel-button"></mat-icon>
      </div>
    </div>
    <ng-container *ngIf="((isLeadInfoLoaded$ | async) && (leadInfo$ | async).id <0 )">
      <div class="err-div">
        <div class="d-flex flex-wrap justify-content-center err">
          <mat-icon class="m-2">error</mat-icon>
          <div class="p-2">
            <h1 class="">
              Lead Not Found
            </h1>
          </div>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="!((isLeadInfoLoaded$ | async) && (leadInfo$ | async).id <0 )">
      <div class="layout-container row" #target>
        <div class="left-layout-container col-md-4">
          <dq-client-dialog-user-general></dq-client-dialog-user-general>
        </div>
        <div class="right-layout-container col-md-8">
          <div class="top-navigation-container dq-box-shadow">
            <ng-container *ngIf="activeTab$ | async as tab">
              <div class="top-navigation-btn" [ngClass]="{'top-navigation-btn-active': tab==1}"
                (click)="onTopNavigate(1)">
                <mat-icon>phone</mat-icon>
                <span class="top-navigation-btn-label">Communication</span>
              </div>
              <div class="top-navigation-btn" [ngClass]="{'top-navigation-btn-active': tab==2}"
                (click)="onTopNavigate(2)">
                <mat-icon>apartment</mat-icon>
                <span class="top-navigation-btn-label">Inventory</span>
              </div>
              <div class="top-navigation-btn" [ngClass]="{'top-navigation-btn-active': tab==3}"
                (click)="onTopNavigate(3)">
                <mat-icon>calendar_today</mat-icon>
                <span class="top-navigation-btn-label">Appointments</span>
              </div>
              <div class="top-navigation-btn" [ngClass]="{'top-navigation-btn-active': tab==4}" (click)="onTopNavigate(4)">
                <mat-icon>description</mat-icon>
                <span class="top-navigation-btn-label">Applications</span>
              </div>
            </ng-container>
          </div>

          <ng-container *ngIf="(activeTab$ | async) == 1">
            <div class="right-top-container dq-box-shadow">
              <mat-tab-group (selectedIndexChange)="handleSelectedTabChange($event)" #topTabGroup dynamicHeight>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">forum</mat-icon>
                  </ng-template>
                  <div class="tab-content">
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">email</mat-icon>
                    Send Email
                  </ng-template>
                  <div class="tab-content">
                    <ng-container *ngIf="selectedTabIndex == 1">
                      <dq-client-dialog-email-editor></dq-client-dialog-email-editor>
                    </ng-container>
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">textsms</mat-icon>
                    Text Message
                  </ng-template>
                  <div class="tab-content">
                    <ng-container *ngIf="selectedTabIndex == 2">
                      <dq-client-dialog-lead-quick-text-message></dq-client-dialog-lead-quick-text-message>
                    </ng-container>
                  </div>
                </mat-tab>
                <mat-tab>
                  <ng-template mat-tab-label>
                    <mat-icon class="tab-icon">note_add</mat-icon>
                    Create Note
                  </ng-template>
                  <div class="tab-content">
                    <dq-client-dialog-lead-note></dq-client-dialog-lead-note>
                  </div>
                </mat-tab>
              </mat-tab-group>
            </div>
            <div class="right-bottom-container">
              <dq-client-dialog-activities (reply)="replyEmail($event)" (replyAll)="replyEmailAll($event)"
                (forward)="forwardEmail($event)"></dq-client-dialog-activities>
            </div>
          </ng-container>
          <ng-container *ngIf="(activeTab$ | async) == 2">
            <dq-client-dialog-inventory></dq-client-dialog-inventory>
          </ng-container>
          <ng-container *ngIf="(activeTab$ | async) == 3">
            <dq-client-dialog-appointment></dq-client-dialog-appointment>
          </ng-container>
          <ng-container *ngIf="(activeTab$ | async) == 4">
            <dq-client-dialog-rental-application></dq-client-dialog-rental-application>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </div>
</div>
