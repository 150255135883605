<div class="dashq-support-component">
    <div class="main-container dq-box-shadow">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <h2>DashQ Support</h2>
                </div>
                <div class="col-12">
                    <h3>How can we help?</h3>
                </div>
            </div>
            <div class="form-div">
                <form [formGroup]="supportForm">
                    <div class="row">
                        <mat-form-field appearance="outline" class="col-sm-6">
                            <mat-label>First Name
                            </mat-label>
                            <input matInput formControlName="firstName" name="firstName">
                            <mat-error *ngIf="supportForm.get('firstName').invalid">
                                First name is required
                            </mat-error>
                        </mat-form-field>
                        <mat-form-field appearance="outline" class="col-sm-6">
                            <mat-label>Last Name
                            </mat-label>
                            <input matInput formControlName="lastName" name="lastName">
                            <mat-error *ngIf="supportForm.get('lastName').invalid">
                                Last name is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                        <mat-form-field appearance="outline" class="col-12">
                            <mat-label>Email
                            </mat-label>
                            <input matInput formControlName="email" name="email">
                            <mat-icon matSuffix svgIcon="envelope" class="input-icon"></mat-icon>
                            <mat-error *ngIf="supportForm.get('email').invalid">
                                Email is required
                            </mat-error>
                        </mat-form-field>
                    </div>
                    <div class="row">
                            <mat-form-field appearance="outline" class="col-12">
                                <mat-label>How can we help?
                                </mat-label>
                                <textarea matInput formControlName="message" class="message-textarea"></textarea>
                            </mat-form-field>
                    </div>
                    <div class="row">
                        <div class="spinner-button-div col-12">
                            <dq-spinner-button class="submit-button" (click)="send()" type="button" [options]="spinnerButtonOptions"></dq-spinner-button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
