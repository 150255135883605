<div [formGroup]="taskCreateForm">
    <div class="dq-row">
        <mat-form-field appearance="outline" class="col-12">
            <mat-label>Task Name</mat-label>
            <input type="text" matInput formControlName="taskName" required placeholder="task name">
        </mat-form-field>
        <mat-form-field class="col-12" appearance="outline">
            <mat-label>Task</mat-label>
            <mat-select formControlName="taskType" required>
                <mat-option *ngFor="let taskType of taskTypes" [value]="taskType.value">
                    {{taskType.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12" appearance="outline">
            <mat-label>Assign To</mat-label>
            <mat-select formControlName="userId" required>
                <mat-option *ngFor="let user of taskAssignUsers$ | async" [value]="user.id">
                    {{user.name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="col-12" appearance="outline" (click)="moveInDatepicker.open()">
            <mat-label>Date</mat-label>
            <input readonly matInput required formControlName="date" required [min]="today"
                [matDatepicker]="moveInDatepicker">
            <mat-datepicker-toggle matSuffix [for]="moveInDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #moveInDatepicker></mat-datepicker>
        </mat-form-field>
        <mat-form-field class="col-12 time-mat-field" appearance="outline">
            <mat-label>Click to select time</mat-label>
            <input matInput formControlName="time" aria-label="Click to select time" [ngxTimepicker]="timePicker" readonly>
            <ngx-material-timepicker #timePicker [theme]="dashQTheme"></ngx-material-timepicker>
        </mat-form-field>
        <mat-form-field class="col-12" appearance="outline">
            <mat-label>TimeZone</mat-label>
            <mat-select formControlName="timeZone" required>
                <mat-option *ngFor="let timeZone of timeZoneOptions" [value]="timeZone.id">
                    {{timeZone.label}}</mat-option>
            </mat-select>
        </mat-form-field>
    </div>
</div>
<div class="button-div col-12">
    <dq-spinner-button class="button" type="button" (click)="create()" [options]="spinnerCreateButtonOptions">
    </dq-spinner-button>
</div>