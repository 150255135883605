import { Component, OnInit, ChangeDetectionStrategy, ViewEncapsulation, ChangeDetectorRef, OnDestroy, Inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import {  MAT_DIALOG_DATA , MatDialog, MatDialogRef} from '@angular/material/dialog';
import { select, Store } from '@ngrx/store';
import { selectUserId, selectUserTimeZone } from 'app/core/auth/auth.selectors';
import { TenantDialogService } from 'app/features/tenant-dialog/tenant-dialog.service';
import { State } from 'app/features/tenant-dialog/tenant-dialog.state';
import { TaskAssignUser, TaskCreateParam, TaskReminderTimeBeforeOption, TaskReminderType, TaskType, TASK_REMINDER_TIME_BEFORE_OPTION, TASK_REMINDER_TYPES, TASK_TYPES } from 'app/shared/models/task.model';
import { TimeZone, TIME_ZONES } from 'app/shared/models/timezone.model';

import { NgxMaterialTimepickerTheme } from 'ngx-material-timepicker';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { actionTenantDialogTasktaskCreateRequested, tenantDialogTaskActions } from '../../tenant-dialog-task.action';
import { selectTenantDialogTaskAssignUsers, selectTenantDialogTaskIsTaskCreatedSuccessfully, selectTenantDialogTaskIsTaskCreating } from '../../tenant-dialog-task.selector';
import { MatProgressButtonOptions } from '../../../../../../shared/components/spinner-button/spinner-button.interface';
import { ThemeService } from 'app/shared/services/theme.service';

@Component({
  selector: 'dq-tenant-dialog-task-create-dialog',
  templateUrl: './tenant-dialog-task-create-dialog.component.html',
  styleUrls: ['./tenant-dialog-task-create-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class TenantDialogTaskCreateDialogComponent implements OnInit, OnDestroy {

  constructor(
    private store: Store<State>,
    private formBuilder: FormBuilder,
    private dialogRef: MatDialogRef<TenantDialogTaskCreateDialogComponent>,
    private cdr: ChangeDetectorRef,
    private themeService: ThemeService,
    @Inject(MAT_DIALOG_DATA) public tenantId: number,
    private tenantDialogService: TenantDialogService
  ) {
    if (tenantId) {
      this.tenantId = tenantId
    }
  }

  dashQTheme: NgxMaterialTimepickerTheme;

  spinnerCreateButtonOptions: MatProgressButtonOptions = {
    active: false,
    text: 'Create',
    spinnerSize: 18,
    flat: true,
    fullWidth: true,
    disabled: false,
    mode: 'indeterminate'
  };

  unsubscribe: Subject<void> = new Subject();

  taskTypes: TaskType[] = TASK_TYPES
  taskReminderTypes: TaskReminderType[] = TASK_REMINDER_TYPES
  taskReminderTimeBeforeOptions: TaskReminderTimeBeforeOption[] = TASK_REMINDER_TIME_BEFORE_OPTION

  isCreating$: Observable<boolean>
  isCreatedSuccessfully$: Observable<boolean>

  taskAssignUsers$: Observable<TaskAssignUser[]>

  today: Date = new Date()

  timeZoneOptions: TimeZone[] = TIME_ZONES

  userTimeZone$: Observable<string>
  userId$: Observable<number>


  taskCreateForm: FormGroup = this.formBuilder.group({
    taskName: new FormControl('', [Validators.required]),
    taskType: new FormControl('', [Validators.required]),
    tenantId: new FormControl(this.tenantId, [Validators.required]),
    userId: new FormControl('', [Validators.required]),
    date: new FormControl('', [Validators.required]),
    time: new FormControl('', [Validators.required]),
    timeZone: new FormControl('', [Validators.required]),
    taskReminderType: new FormControl('Email', [Validators.required]),
    taskReminderTimeBefore: new FormControl(30, [Validators.required]),
  })

  ngOnInit(): void {
    this.dashQTheme = this.themeService.getM3TimePickerTheme();
    this.store.dispatch(tenantDialogTaskActions.actionTenantDialogTasktaskAssignUsersRequested())
    this.isCreating$ = this.store.pipe(select(selectTenantDialogTaskIsTaskCreating))
    this.isCreatedSuccessfully$ = this.store.pipe(select(selectTenantDialogTaskIsTaskCreatedSuccessfully))
    this.taskAssignUsers$ = this.store.pipe(select(selectTenantDialogTaskAssignUsers))

    this.isCreating$.pipe(takeUntil(this.unsubscribe)).subscribe(isCreating => {
      this.spinnerCreateButtonOptions.active = isCreating
      this.cdr.markForCheck()
    })

    this.isCreatedSuccessfully$.pipe(takeUntil(this.unsubscribe)).subscribe(isCreatedSuccessfully => {
      if (isCreatedSuccessfully) {
        this.dialogRef.close(true);
      }
    })

    this.userTimeZone$ = this.store.pipe(select(selectUserTimeZone))
    this.userId$ = this.store.pipe(select(selectUserId))

    this.userTimeZone$.pipe(takeUntil(this.unsubscribe)).subscribe(userTimeZone => {
      if (userTimeZone && TIME_ZONES.find(x => x.id == userTimeZone)) {
        this.taskCreateForm.get('timeZone').setValue(userTimeZone)
      }
    })

    this.userId$.pipe(takeUntil(this.unsubscribe)).subscribe(userId => {
      this.taskCreateForm.get('userId').setValue(userId)
    })
  }

  create() {
    if (this.spinnerCreateButtonOptions.active) {
      return
    }
    this.taskCreateForm.markAllAsTouched()
    if (this.taskCreateForm.invalid) {
      return
    }
    let param: TaskCreateParam = {
      taskName: this.taskCreateForm.get('taskName').value,
      tenantId: this.taskCreateForm.get('tenantId').value,
      taskType: this.taskCreateForm.get('taskType').value,
      userId: this.taskCreateForm.get('userId').value,
      date: this.taskCreateForm.get('date').value,
      time: this.taskCreateForm.get('time').value,
      timeZone: this.taskCreateForm.get('timeZone').value,
      taskReminderType: this.taskCreateForm.get('taskReminderType').value,
      taskReminderTimeBefore: this.taskCreateForm.get('taskReminderTimeBefore').value,
    }
    this.store.dispatch(actionTenantDialogTasktaskCreateRequested({ param }))
  }

  cancel() {
    this.dialogRef.close(false);
  }


  close() {
    this.dialogRef.close(false);
  }

  ngOnDestroy(): void {
    this.unsubscribe.next();
    this.unsubscribe.complete();
    this.store.dispatch(tenantDialogTaskActions.actionTenantDialogTaskTaskCreateStateReset())
  }

}
