<div class="tenant-dialog-task-edit-dialog-component">
  <div class="edit-task-dialog-title">
    <div>
      <mat-icon class="task-icon">list</mat-icon>
      <h4>Edit</h4>
    </div>
    <button mat-icon-button (click)="$event.preventDefault();close()">
      <mat-icon svgIcon="times" class="icon cancel"></mat-icon>
    </button>
  </div>
  <mat-divider></mat-divider>
  <div class="form-container">
    <div [formGroup]="taskEditForm">
      <div class="container">
        <div class="row">
          <mat-form-field appearance="outline" class="col-12">
            <mat-label>Task Name</mat-label>
            <input type="text" matInput formControlName="taskName" required placeholder="task name">
          </mat-form-field>
          <mat-form-field class="col-12" appearance="outline">
            <mat-label>Task</mat-label>
            <mat-select formControlName="taskType" required>
              <mat-option *ngFor="let taskType of taskTypes" [value]="taskType.value">
                {{ taskType.name }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <mat-form-field class="col-12" appearance="outline" (click)="moveInDatepicker.open()">
            <mat-label>Date</mat-label>
            <input readonly matInput formControlName="date" required [min]="today"
                   [matDatepicker]="moveInDatepicker">
            <mat-datepicker-toggle matSuffix [for]="moveInDatepicker"></mat-datepicker-toggle>
            <mat-datepicker #moveInDatepicker></mat-datepicker>
          </mat-form-field>

          <mat-form-field class="col-12 time-mat-field" appearance="outline">
            <mat-label>Click to select time</mat-label>
            <input matInput formControlName="time" aria-label="Click to select time" [ngxTimepicker]="timePicker" readonly>
            <ngx-material-timepicker [defaultTime]="taskEditForm.get('time').value" #timePicker [theme]="dashQTheme"></ngx-material-timepicker>
          </mat-form-field>

          <mat-form-field class="col-12" appearance="outline">
            <mat-label>TimeZone</mat-label>
            <mat-select formControlName="timeZone" required>
              <mat-option *ngFor="let timeZone of timeZoneOptions" [value]="timeZone.id">
                {{ timeZone.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
          <!-- <mat-form-field class="col-12" appearance="outline">
              <mat-label>Remind By</mat-label>
              <mat-select formControlName="taskReminderType" required>
                  <mat-option *ngFor="let taskReminderType of taskReminderTypes"
                      [value]="taskReminderType.value">
                      {{taskReminderType.label}}</mat-option>
              </mat-select>
          </mat-form-field>
          <mat-form-field class="col-12" appearance="outline">
              <mat-label>Remind Before</mat-label>
              <mat-select formControlName="taskReminderTimeBefore" required>
                  <mat-select-trigger>
                      {{taskEditForm.get('taskReminderTimeBefore').value}} mins
                  </mat-select-trigger>
                  <mat-option *ngFor="let taskReminderTimeBefore of taskReminderTimeBeforeOptions"
                      [value]="taskReminderTimeBefore.value">
                      {{taskReminderTimeBefore.value}} mins</mat-option>
              </mat-select>
          </mat-form-field> -->
        </div>
      </div>
    </div>
    <div class="button-div col-12">
      <button mat-stroked-button class="delete-button" (click)="delete()">Delete</button>
      <button mat-stroked-button class="button cancel-button" (click)="cancel()">Cancel</button>
      <dq-spinner-button class="button" type="button" (click)="save()" [options]="spinnerSaveButtonOptions">
      </dq-spinner-button>
    </div>
  </div>
</div>
