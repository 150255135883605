import { NgxMatIntlTelInputComponent } from 'ngx-mat-intl-tel-input';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgxSliderModule } from '@angular-slider/ngx-slider';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule, DatePipe, DecimalPipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatOptionModule, MatRipple, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { RouterModule } from '@angular/router';
import { FaIconLibrary, FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { faGithub, faMediumM } from '@fortawesome/free-brands-svg-icons';
import {
  faBook,
  faCaretDown,
  faCaretUp,
  faCheck,
  faEdit,
  faExclamationTriangle,
  faFilter,
  faLanguage,
  faLightbulb,
  faPaintBrush,
  faPlus,
  faSquare,
  faStream,
  faTasks,
  faTimes,
  faTrash,
  faWindowMaximize
} from '@fortawesome/free-solid-svg-icons';
import { TranslateModule } from '@ngx-translate/core';
import { EditorModule } from '@tinymce/tinymce-angular';

import { BigInputActionComponent } from './components/big-input/big-input-action/big-input-action.component';
import { BigInputComponent } from './components/big-input/big-input/big-input.component';
import { CalendarComponent } from './components/calendar/calendar.component';
import { CalendarHeaderComponent } from './components/calendar/components/calendar-header/calendar-header.component';
import { BuildingFooterComponent } from './components/card/components/building-footer/building-footer.component';
import { PropertyFooterComponent } from './components/card/components/property-footer/property-footer.component';
import { ConfirmBottomSheetComponent } from './components/confirm-bottom-sheet/confirm-bottom-sheet.component';
import {
  DeleteRemoveConfirmDialogComponent
} from './components/delete-remove-confirm/delete-remove-confirm-dialog/delete-remove-confirm-dialog.component';
import { DialogPadComponent } from './components/dialog-pad/dialog-pad.component';
import { DateRangeDirective } from './directives/date-range-picker-range-limit.directive';
import { NumberDotCommaFormatDirective } from './directives/number-dot-comma-format.directive';
import PhoneNumberDirective from './directives/phone-number.directive';
import { ResizeColumnDirective } from './directives/resize-column.directive';
import { DisplayCustomAlertsModalComponent } from './components/display-custom-alerts-modal/display-custom-alerts-modal.component';
import { DynamicFormFillerComponent } from './components/dynamic-form-filler/dynamic-form-filler.component';
import { EmptyErrorComponent } from './components/empty-error/empty-error/empty-error.component';
import { GlobalSearchComponent } from './components/global-search/global-search/global-search.component';
import { ImageUploadDialogComponent } from './components/image-upload-dialog/image-upload-dialog.component';

import { LoadingComponent } from './components/loading/loading.component';
import { ManualSliderComponent } from './components/manual-slider/manual-slider.component';
import { NavCardComponent } from './components/nav-card/nav-card.component';
import { PhoneNumberMenuComponent } from './components/phone-number-menu/phone-number-menu/phone-number-menu.component';
import { DigiDecimalCommaPipe } from './pipes/digi-decimal-comma-pipe.pipe';
import { DigiDecimalPipe } from './pipes/digi-decimal-pipe.pipe';
import { DigiPhoneNumberPipe } from './pipes/digi-phone-number.pipe';
import { DigiTextSubPipe } from './pipes/digi-text-sub.pipe ';
import { ReplacePipe } from './pipes/replacePipe';
import { SafeHtmlPipe } from './pipes/safeHtml.pipe';
import { TimerDurationPipe } from './pipes/timer.pipe';
import { PressNHoldButtonComponent } from './components/press-n-hold/press-n-hold-button/press-n-hold-button.component';
import {
  PressNHoldIconButtonComponent
} from './components/press-n-hold/press-n-hold-icon-button/press-n-hold-icon-button.component';
import { RtlSupportDirective } from './components/rtl-support/rtl-support.directive';
import { StylesTogglesComponent } from './components/styles-toggles/styles-toggles.component';
import { SurveyComponent } from './components/survey/survey.component';
import {
  ThumbnailAgentLoadingPlaceholderComponent
} from './components/thumbnail-representative-loading-placeholder/thumbnail-representative-loading-placeholder.component';
import {
  TimelineAppointmentComponent
} from './components/timeline/components/timeline-appointment/timeline-appointment.component';
import { TimelineEmailComponent } from './components/timeline/components/timeline-email/timeline-email.component';
import { TimelineNoteComponent } from './components/timeline/components/timeline-note/timeline-note.component';
import { TimelineTextComponent } from './components/timeline/components/timeline-text/timeline-text.component';
import { TimelineUnknownComponent } from './components/timeline/components/timeline-unknown/timeline-unknown.component';
import { TimelineVoiceComponent } from './components/timeline/components/timeline-voice/timeline-voice.component';
import { TimelineWebformComponent } from './components/timeline/components/timeline-webform/timeline-webform.component';
import { TimelineComponent } from './components/timeline/timeline/timeline.component';
import { WeekScheduleComponent } from './components/week-schedule/week-schedule.component';
import { AudioPlayerComponent } from './components/audio-player/audio-player.component';
import { FileSizePipe } from './pipes/fileSize.pipe';
import { ActionDialogComponent } from './components/action-dialog/action-dialog-component.component';
import { MatBadgeModule } from '@angular/material/badge';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { IndexSearchComponent } from './components/index-search/index-search.component';
import { SpinnerButtonModule } from './components/spinner-button/spinner-button.module';
import { MultiPurposeDialogComponent } from './components/multi-purpose-dialog/multi-purpose-dialog.component';

@NgModule({
  imports: [
    CommonModule,
    DragDropModule,
    EditorModule,
    FontAwesomeModule,
    FormsModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatOptionModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NgxMatIntlTelInputComponent,
    NgxMatSelectSearchModule,
    NgxPaginationModule,
    NgxSliderModule,
    OverlayModule,
    ReactiveFormsModule,
    RouterModule,
    SpinnerButtonModule,
    TranslateModule,
    MatRippleModule
  ],
  declarations: [
    ActionDialogComponent,
    AudioPlayerComponent,
    BigInputActionComponent,
    BigInputComponent,
    BuildingFooterComponent,
    CalendarComponent,
    CalendarHeaderComponent,
    ConfirmBottomSheetComponent,
    DateRangeDirective,
    DeleteRemoveConfirmDialogComponent,
    DialogPadComponent,
    DigiDecimalCommaPipe,
    DigiDecimalPipe,
    DigiPhoneNumberPipe,
    DigiTextSubPipe,
    DisplayCustomAlertsModalComponent,
    DynamicFormFillerComponent,
    EmptyErrorComponent,
    FileSizePipe,
    FileSizePipe,
    GlobalSearchComponent,
    ImageUploadDialogComponent,
    IndexSearchComponent,
    LoadingComponent,
    ManualSliderComponent,
    NavCardComponent,
    NumberDotCommaFormatDirective,
    PhoneNumberDirective,
    PhoneNumberMenuComponent,
    PressNHoldButtonComponent,
    PressNHoldButtonComponent,
    PressNHoldIconButtonComponent,
    PropertyFooterComponent,
    ReplacePipe,
    ResizeColumnDirective,
    RtlSupportDirective,
    SafeHtmlPipe,
    StylesTogglesComponent,
    SurveyComponent,
    ThumbnailAgentLoadingPlaceholderComponent,
    TimelineAppointmentComponent,
    TimelineComponent,
    TimelineEmailComponent,
    TimelineNoteComponent,
    TimelineTextComponent,
    TimelineUnknownComponent,
    TimelineVoiceComponent,
    TimelineWebformComponent,
    TimerDurationPipe,
    WeekScheduleComponent,
    MultiPurposeDialogComponent
  ],
  exports: [
    AudioPlayerComponent,
    BigInputActionComponent,
    BigInputComponent,
    BuildingFooterComponent,
    CalendarComponent,
    CalendarHeaderComponent,
    CommonModule,
    DateRangeDirective,
    DialogPadComponent,
    DigiDecimalPipe,
    DigiPhoneNumberPipe,
    DigiTextSubPipe,
    DisplayCustomAlertsModalComponent,
    DragDropModule,
    EditorModule,
    EmptyErrorComponent,
    FileSizePipe,
    FontAwesomeModule,
    FormsModule,
    GlobalSearchComponent,
    IndexSearchComponent,
    LoadingComponent,
    ManualSliderComponent,
    MatAutocompleteModule,
    MatBadgeModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSliderModule,
    MatSnackBarModule,
    MatStepperModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    NavCardComponent,
    NgxMatIntlTelInputComponent,
    NgxMatSelectSearchModule,
    NgxSliderModule,
    NumberDotCommaFormatDirective,
    OverlayModule,
    PhoneNumberDirective,
    PhoneNumberMenuComponent,
    PressNHoldButtonComponent,
    PressNHoldIconButtonComponent,
    PropertyFooterComponent,
    ReactiveFormsModule,
    ResizeColumnDirective,
    RtlSupportDirective,
    SafeHtmlPipe,
    SpinnerButtonModule,
    StylesTogglesComponent,
    SurveyComponent,
    ThumbnailAgentLoadingPlaceholderComponent,
    TimelineComponent,
    TimerDurationPipe,
    TranslateModule,
    WeekScheduleComponent,
    MultiPurposeDialogComponent
  ],
  providers: [
    DecimalPipe,
    DigiDecimalPipe,
    DigiTextSubPipe,
    DigiPhoneNumberPipe,
    NumberDotCommaFormatDirective,
    DateRangeDirective,
    ResizeColumnDirective,
    DigiDecimalCommaPipe,
    DatePipe
  ]
})
export class SharedModule {
  constructor(faIconLibrary: FaIconLibrary) {
    faIconLibrary.addIcons(
      faGithub,
      faMediumM,
      faPlus,
      faEdit,
      faTrash,
      faTimes,
      faCaretUp,
      faCaretDown,
      faExclamationTriangle,
      faFilter,
      faTasks,
      faCheck,
      faSquare,
      faLanguage,
      faPaintBrush,
      faLightbulb,
      faWindowMaximize,
      faStream,
      faBook
    );
  }
}
