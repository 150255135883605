<mat-dialog-content class="application-form-dialog-content">
    <div class="search-bar">
        <mat-form-field appearance="outline" class="full-width no-subscript-wrapper">
            <mat-label>Search Form Templates</mat-label>
            <input matInput [formControl]="searchControl" placeholder="Search by friendly name">
            <mat-icon matSuffix>search</mat-icon>
        </mat-form-field>
    </div>
    <ng-container *ngIf="!(isRentalApplicationAddNewFormLoading$ | async); else formTemplateLoading">
        <ng-container *ngIf="(filteredRentalFormTemplates$ | async).length > 0; else formTemplateEmpty">
            <div class="form-div">
                <form [formGroup]="addNewForm">
                    <ng-container *ngFor="let formTemplate of filteredRentalFormTemplates$ | async; let i = index">
                        <div class="form-item" [ngClass]="isFormSelected(i)" formArrayName="formTemplates">
                            <mat-checkbox [formControlName]="i">
                                {{formTemplate.friendlyName}} -
                                {{getVersionLanguageLabel(formTemplate.versionLanguage)}}
                            </mat-checkbox>
                        </div>
                    </ng-container>
                </form>
            </div>
        </ng-container>
    </ng-container>
    <ng-template #formTemplateEmpty>
        <div class="dq-flex-centered loading-spinner">
            No form available for this rental application.
        </div>
    </ng-template>
    <ng-template #formTemplateLoading>
        <div class="dq-flex-centered loading-spinner">
            <mat-spinner></mat-spinner>
        </div>
    </ng-template>
</mat-dialog-content>

<div class="dq-row">
    <div class="col-12 button-div">
        <dq-spinner-button [disabled]="!addNewForm.valid" class="generic-button confirm-button" type="button"
            (click)="submitForm()" [options]="spinnerAddNewFormButtonOptions"></dq-spinner-button>
    </div>
</div>