export const BED_OPTIONS = [
  {
    id: 'bed0',
    label: 'Studio',
    count: 0
  },
  {
    id: 'bed1',
    label: '1 Bed',
    count: 1
  },
  {
    id: 'bed1-den',
    label: '1 Bed + Den',
    count: 1.1
  },
  {
    id: 'bed2',
    label: '2 Beds',
    count: 2
  },
  {
    id: 'bed2-den',
    label: '2 Beds + Den',
    count: 2.1
  },
  {
    id: 'bed3',
    label: '3 Beds',
    count: 3
  },
  {
    id: 'bed4',
    label: '4+ Beds',
    count: 4
  }
]

export const BATH_OPTIONS = [
  {
    id: 'bath0',
    label: 'Any',
    count: 0
  },
  {
    id: 'bath1',
    label: '1',
    count: 1
  },
  {
    id: 'bath2',
    label: '2',
    count: 2
  },
  {
    id: 'bath3',
    label: '3',
    count: 3
  },
  {
    id: 'bath4',
    label: '4+',
    count: 4
  }
]

export const OCCUPENCY_OPTIONS = [
  {
    id: 'occupency0',
    label: 'Occupied',
    value: 'Occupied'
  },
  {
    id: 'occupency1',
    label: 'Vacant',
    value: 'Vacant'
  },
  {
    id: 'occupency2',
    label: 'Not Found',
    value: 'Not Found'
  }
]

export const LEASED_OPTIONS = [
  {
    id: 'leased0',
    label: 'Leased',
    value: 'Leased'
  },
  {
    id: 'leased1',
    label: 'On Notice',
    value: 'OnNotice'
  },
  {
    id: 'leased2',
    label: 'Available',
    value: 'Available'
  },
  {
    id: 'leased3',
    label: 'Other',
    value: 'Other'
  },
  {
    id: 'leased4',
    label: 'Leased Reserved',
    value: 'LeasedReserved'
  }
]

export const UNIT_ECONOMIC_OPTIONS = [
  {
    id: 'unitEconomic0',
    label: 'Residential',
    value: 'Residential'
  },
  {
    id: 'unitEconomic1',
    label: 'Model',
    value: 'Model'
  },
  {
    id: 'unitEconomic2',
    label: 'Down',
    value: 'Down'
  }
]

export interface StatusOption {
  friendlyName: string;
  value: string;
}


export interface Area {
  id: string;
  name: string;
  shortName: string;
  uri: string
}

export interface IProperty {
  id: number;
  photo: string;
  name: string;
  style: string;
  minPrice: number;
  maxPrice: number;
  address: string;
  unitCount: number;
}

export interface IBuilding {
  id: number
  name: string
  address: string
  photoUrl: string
  activationDate: string
}

export interface IUnit {
  id: number;
  buildingId: number;
  yardiUnitIdentifier: string
  yardiBuildingPropertyId: number
  buildingName: string;
  buildingAddress: string;
  vacancyDate?: string;
  madeReadyDate?: string;
  isFurnished?: boolean;
  beds?: number;
  baths?: number;
  price?: number;
  floorPlanId?: string;
  specialPrice?: number
  buildingPhotoUrl: string;
  unitSquareFeet: number
  isReserved?: boolean;
  reservedBy?: string;
  canRelease?: boolean;
  buildingPropertyAmenities: IUnitAmenity[];
}

export interface IUnitAmenity {
  id: number;
  type: string;
  description: string;
  buildingPropertyInventoryId : number;
}

export interface Photo {
  sequenceNumber?: number
  photoType?: string;
  photoSize?: string;
  photoName?: string;
  photoDescription?: string;
  altText?: string;
  photoUri?: string;
}

export interface UnitFilterOptions {
  bedOptions: BedOption[];
  bathOptions: BathOption[]
}

export interface BedOption {
  id: string;
  label: string;
  count: number
}

export interface BathOption {
  id: string;
  label: string;
  count: number
}

export interface InventoryQeurytFilter {
  propertyName: string,
  baseFilters: InventoryBaseFilters,
  moreFilters: InventoryMoreFilters
}

export interface InventoryMoreFilters {
  occupencyStatuses?: string[];
  leasedStatuses?: string[];
  economicStatuses?: string[];
}

export interface InventoryBaseFilters {
  isMadeReady: boolean;
  isNotReady: boolean;
  isReserved: boolean;
  minPrice: number;
  maxPrice: number;
  minSqft?: number;
  maxSqft?: number;
  beds: number[];
  bath: number
  baths?: number[];
  vacancyStartDate: string;
  vacancyEndDate: string;
  madeReadyStartDate: string;
  madeReadyEndDate: string;
  buildingPropertyIds?: number[];
  buildingIds?: number[];
}

export interface InventoryQueryParams {
  isMadeReady: boolean,
  isNotReady: boolean,
  isReserved: boolean,
  propertyName: string,
  areaId?: string
  minPrice: number;
  maxPrice: number;
  beds: number[];
  baths: number;
  vacancyStartDate: string;
  vacancyEndDate: string;
  madeReadyStartDate: string;
  madeReadyEndDate: string;
  yardiBuildingPropertyIds?: number[];
  yardiBuildingIds?: number[];
  occupencyStatuses: string[];
  leasedStatuses: string[];
  economicStatuses: string[];
  page: number;
  pageSize: number
}

export interface ReserveReleaseResult {
  id: number
  canRelease: boolean
  isReserved: boolean
  reservedBy: string
}

export interface BuildingProperty {
  buildingPropertyId: number,
  name: string,
  displayAddress: string,
  numberOfUnits: number,
  externalPlatformId: number,
  buildingId: number,
  activationDate: string
}

export interface BuildingWeeklyAvailability {
  weekStartDate: string;
  weekEndDate: string;
  weeklyScheduleDays: WeeklyAvailabilityDays[];
}

export interface WeeklyAvailabilityDays {
  date: string;
  weeklyScheduleItems: WeeklyAvailabilityItem[]
}

export interface WeeklyAvailabilityItem {
  bookingSlotDateTime: string
  calendarIds: number[]
  isWithinNoticeOfEntry: boolean
}

export interface IBuilding {
  id: number;
  photoUrl: string;
  name: string;
  style: string;
  address: string;
  activationDate: string
}



export interface BookingLeadSource {
  id: number;
  shortName: string;
  isDefault: boolean;
}

export interface RepresentativeThumbnail {
  id: number;
  firstName: string;
  lastName: string;
  uri: string;
  photoUri: string;
}

export interface Photo {
  sequenceNumber?: number
  type?: string;
  size?: string;
  name?: string;
  description?: string;
  altText?: string;
  uri?: string;
}

export interface BookingEvent {
  id: number
  name: string
  uri: string
  description: string
  duration?: number
  timeZone?: string,
  representativeId: string
  representativeCalendarTypeId?: string
  representativeCalendarType: BookingRepresentativeCalendarType
  externalCalendarRef: string
  colorCode: string
  bookingStartTimeLocal: string
  bookingEndTimeLocal: string
}

export interface BookingRepresentativeCalendarType {
  name: string;
  type: string;
}

export interface BookingTimeSlot {
  id: string;
  label: string;
}

export interface Photo {
  sequenceNumber?: number
  type?: string;
  size?: string;
  name?: string;
  description?: string;
  altText?: string;
  uri?: string;
}
