import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { map } from 'rxjs/operators';
import { IWebApiResponse } from 'app/core/response/response.model';

@Injectable()
export class BookingService {

  constructor(
    private http: HttpClient,
  ) {

  }

  getLeadPreferences(leadId: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-detail/getLeadInterests?leadId=${leadId}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  checkRepAccountMicrosoftConnected(repUserId: number) {
    return this.http.get(`${environment.apiBaseUrl}microsoftIdentity/CheckAccountByUserId?userId=${repUserId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  checkRepAccountZoomConnected(repUserId: number) {
    return this.http.get(`${environment.apiBaseUrl}zoomIdentity/CheckAccountByUserId?userId=${repUserId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getLeadsBySearchKeyWord(search: string, page: number, pageSize: number) {
    return this.http.get(`${environment.apiBaseUrl}lead-query/GetLeadsByText?search=${search}&page=${page}&pageSize=${pageSize}`)
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  cancelAppointment(id: number) {
    return this.http.post(`${environment.apiBaseUrl}booking/CancelRepresentativeAppointment`,
      id
    )
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getRepresentativeUpcomingAppointments(userId: number) {
    return this.http.get(`${environment.apiBaseUrl}booking/GetRepresentativeUpcomingAppointments?userId=${userId}`).pipe(map((response: IWebApiResponse) => {
      return response;
    })
    );
  }

  GetRepresentativeWeeklyAppointments(representativeId: number, date: string) {
    return this.http.get(`${environment.apiBaseUrl}booking/GetRepresentativeWeeklyAppointments?representativeId=${representativeId}&date=${date}`).pipe(map((response: IWebApiResponse) => {
      return response;
    })
    );
  }

  createAppointment(formType: string, form: any) {
    return this.http.post(`${environment.apiBaseUrl}booking/CreateAppointment?formType=${formType}`,
      form
    )
      .pipe(map((response: IWebApiResponse) => {
        return response;
      })
      );
  }

  getEventCalendar(repUserId: number, calendarId: number) {
    return this.http.get(`${environment.apiBaseUrl}booking/GetRepresentativeBookingCalendar?userId=${repUserId}&calendarId=${calendarId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getEventCalendarAvailability(repUserId: number, calendarId: number, bookDate: string, timeZone?: string) {
    return this.http.get(`${environment.apiBaseUrl}booking/GetRepresentativeBookingCalendarAvailabilities?userId=${repUserId}&calendarId=${calendarId}&bookingDate=${bookDate}&timeZone=${timeZone}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getEventCalendarOverwriteAvailability(repUserId: number, calendarId: number, bookDate: string, timeZone?: string) {
    return this.http.get(`${environment.apiBaseUrl}booking/GetRepresentativeBookingCalendarAvailabilities?userId=${repUserId}&calendarId=${calendarId}&bookingDate=${bookDate}&timeZone=${timeZone}&overrideSchedule=true`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }


  getEventCalendarLeadSource() {
    return this.http.get(`${environment.apiBaseUrl}booking/GetLeadSources`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRepresentativeEventList(repUserId: number) {
    return this.http.get(`${environment.apiBaseUrl}booking/GetRepresentativeBookingCalendars?userId=${repUserId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }


  getRepresentativesByBuildingId(buildingId: string) {
    return this.http.get(`${environment.apiBaseUrl}booking/GetRepresentativesByBuilding?BuildingId=${buildingId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getBuildingsByAccount(accountId: string) {
    return this.http.get(`${environment.apiBaseUrl}booking/GetBuildingsByAccountId?accountId=${accountId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRepresentativeListByAccount() {
    return this.http.get(`${environment.apiBaseUrl}booking/GetRepresentativesListByAccount`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getRepresentativeAccounts() {
    return this.http.get(`${environment.apiBaseUrl}booking/GetBookingAccounts`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getInventoryUnitsByBuildingId(buildingId: string) {
    return this.http.get(`${environment.apiBaseUrl}booking/GetInventoryByBuilding?buildingId=${buildingId}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getBuildingWeeklyAvailability(date: string, buildingId: number) {
    return this.http.get(`${environment.apiBaseUrl}booking/GetWeeklyRepresentativeBookingCalendarAvailabilities?buildingId=${buildingId}&date=${date}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getBuildingWeeklyAvailabilityScheduleOverwrite(date: string, buildingId: number) {
    return this.http.get(`${environment.apiBaseUrl}booking/GetWeeklyOverwritedScheduleAvailabilities?buildingId=${buildingId}&date=${date}`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }


  getBookingBuildings() {
    return this.http.get(`${environment.apiBaseUrl}booking/GetBuildingSettings`)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

  getBookingCalendars(ids: number[]) {
    let url = `${environment.apiBaseUrl}booking/GetAvailableBuildingCalendars?`
    ids.forEach((id, i) => {
      if (i < ids.length - 1) {
        url += `calendarIds=${id}&`
      } else {
        url += `calendarIds=${id}`
      }
    })
    return this.http.get(url)
      .pipe(
        map((response: IWebApiResponse) => {
          return response;
        })
      );
  }

}
